import React from 'react';
import { Avatar as A, AvatarProps } from 'primereact/avatar';
import styled from 'styled-components';

const Avtr = styled(A)`
  width: 3rem;
  height: 3rem;

  &.p-avatar-lg {
    img {
      width: 70%;
      height: 70%;
    }
  }
`;

interface PropsType extends AvatarProps {}

const Avatar = (props: PropsType) => <Avtr {...props} />;
export default Avatar;
