import React, { createContext, useContext, useState } from 'react';
import { oneOfType, object, array } from 'prop-types';

export interface FooterContextType {
  defaultView: boolean;
  setDefaultView: (view: boolean) => void;
}

export const FooterModeContext = createContext<FooterContextType>({ defaultView: true, setDefaultView: () => {} });

export const FooterModeProvider = props => {
  const enrichedDisplayMode = useFooterModeProvider();

  return <FooterModeContext.Provider value={enrichedDisplayMode}>{props.children}</FooterModeContext.Provider>;
};

FooterModeProvider.propTypes = {
  children: oneOfType([object, array]).isRequired,
};

export const useFooterDisplayMode = () => useContext(FooterModeContext);

const useFooterModeProvider = () => {
  const [defaultView, setDefaultView] = useState(true);

  return { defaultView, setDefaultView };
};
