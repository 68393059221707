import React from 'react';
import { Container } from 'components/container';
import { Button } from 'primereact/button';
import { ParallaxBanner } from './index.styled';
import { useState } from 'react';
import PlayerDialog from 'components/player-modal';

const Status = () => {
  const [openPlayer, setOpenPlayer] = useState(false);

  return (
    <ParallaxBanner
      layers={[
        {
          image: '/assets/images/bg-vid.jpg',
          speed: -15,
        },
      ]}
      className="home-stats"
    >
      <Container className="container">
        <Button
          icon="pi pi-play"
          onClick={() => setOpenPlayer(true)}
          className="p-button-rounded p-button-outlined p-button-help"
        />

        <div className="stauts">
          <div className="item">
            <h4>
              1.5<span>Bn</span> +
            </h4>
            <h6>Clients</h6>
          </div>
          <div className="item">
            <h4>
              1000<span>K</span> +
            </h4>
            <h6>Claim your crwdid today!</h6>
          </div>
        </div>
      </Container>
      <PlayerDialog show={openPlayer} videoUrl="6306707711112" onHide={() => setOpenPlayer(false)} />
    </ParallaxBanner>
  );
};

export default Status;
