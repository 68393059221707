import styled from 'styled-components';

export const Section = styled.section`
  padding: 120px 0;
  overflow: hidden;

  .sec-head {
    position: relative;
    margin: 0 auto 80px;

    h6 {
      font-weight: 300;
      font-size: 17px;
      text-transform: uppercase;
      letter-spacing: 7px;
      margin-bottom: 10px;
      text-align: center;
    }

    h3 {
      text-align: center;
      font-size: 48px;
      font-weight: 500;
    }
  }

  @media (max-width: 576px) {
    h6 {
      font-size: 15px !important;
      letter-spacing: 5px !important;
    }

    h3 {
      font-size: 30px !important;
      line-height: 32px !important;
    }
  }
`;

export const Item = styled.div`
  text-align: left;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0px 10px 30px;
  padding: 60px 50px;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.05);

  i {
    text-align: center;
    position: relative;
    width: 80px;
    height: 80px;
    line-height: 80px;
    font-size: 40px;
    margin-bottom: 30px;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: linear-gradient(to right, #00cfe5, #c200db, #e80074);
      z-index: -1;
      opacity: 0.1;
    }
  }

  .details {
    padding-left: 30px;

    h6 {
      line-height: 1.8;
      text-transform: capitalize;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 18px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      line-height: 2;
      margin: 0;
    }
  }

  @media (max-width: 576px) {
    margin: 0px 0px 30px;
    padding: 30px 25px;
    border-radius: 10px;

    .details {
      h6 {
        font-size: 16px;
      }

      p {
        font-size: 14px;
      }
    }
  }
`;
