import React from 'react';
import Input from 'components/input';
import { LinkProps } from 'components/contact-card-details/setup/service';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { useForm } from 'react-hook-form';
import { LinkInputCard } from './index.styled';

interface Props {
  item: LinkProps;
}

const LinkInputForm = ({ item }: Props) => {
  const { control } = useForm();

  return (
    <LinkInputCard>
      <div className="grid m-0">
        <div className="col-3 p-0">
          <Avatar image={item.icon} size="xlarge" />
        </div>
        <div className="col-9 p-0">
          <div className="grid flex-column m-0">
            <div className="col-12 p-0">
              <h3 className="m-0">{item.title}</h3>
            </div>
            <div className="col-12 p-0">
              <div className="grid m-0">
                <div className="col-6 p-0">
                  <Button label="Delete Link" className="p-button-outlined p-button-danger" />
                </div>
                <div className="col-6 py-0">
                  <Button
                    label="Test Link"
                    icon="pi pi-arrow-circle-up"
                    iconPos="right"
                    className="p-button-secondary"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Input label="Link" className="mt-2" name="link[]" control={control as any} />
    </LinkInputCard>
  );
};

export default LinkInputForm;
