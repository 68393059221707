
import styled, { keyframes } from 'styled-components';

export const Section = styled.section`
  padding: 120px 0;
  overflow: hidden;

  .sec-head {
    position: relative;

    h6 {
      font-weight: 300;
      font-size: 17px;
      text-transform: uppercase;
      letter-spacing: 7px;
      margin-bottom: 10px;
    }

    h3 {
      font-size: 48px;
      font-weight: 500;
      margin-bottom: 20px;
    }

    p {
      color: var(--text-color);
      font-size: 16px;
      font-weight: 300;
      line-height: 2;
      margin: 0;
    }
  }

  @media (max-width: 576px) {
  }
`;

const FadeInUp = keyframes`
  0% {
    opacity: 0;
   transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Item = styled.div`
  text-align: center;
  border: 1px solid rgba(255, 255, 255, 0.08);
  height: 160px;
  line-height: 160px;
  margin-bottom: 30px;
  display: flex;
  position: relative;
  cursor: pointer;

  img {
    transition: all 0.4s;
    max-width: 75px;
    margin: auto;
    width: 100%;
    height: auto;
  }

  h6 {
    font-size: 13px;
    width: max-content;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: all 0.3s;
    font-weight: 400;
  }

  &:hover {
    img {
      transform: scale(0);
      opacity: 0;
    }
    h6 {
      opacity: 1 !important;
    }
  }
`;

interface Props {
  index: number;
}
export const SPAN = styled.span<Props>`
  display: inline-block;
  animation: ${FadeInUp} 1s cubic-bezier(0.3, 0, 0.7, 1) both;
  animation-delay: ${({ index }) => `calc(60ms * ${index})`};

  ${Item}:hover & {
  }
`;
