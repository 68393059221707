import styled from 'styled-components';

export const Section = styled.section`
  padding: 100px 0;

  h3 {
    font-size: 48px;
    font-weight: 500;
  }

  h6 {
    font-weight: 300;
    font-size: 17px;
    text-transform: uppercase;
    letter-spacing: 7px;
    margin-bottom: 10px;
    margin: 0 auto 80px;
  }

  @media (max-width: 576px) {
    h3 {
      font-size: 35px;
    }

    h6 {
      font-size: 12px;
      letter-spacing: 5px;
      margin: 0 auto 50px;
    }
  }
`;
