import React from 'react';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import { useNavigate } from 'react-router-dom';
import { BannerWrapper } from './index.styled';

interface Props {
  src: string;
  navigateTo?: string;
}

const Banner = ({ src, navigateTo }: Props) => {
  const navigate = useNavigate();

  return (
    <BannerWrapper>
      <Image src={src} />
      {navigateTo && (
        <Button
          icon="pi pi-arrow-left"
          className="p-button-rounded p-button-secondary p-button-outlined"
          onClick={() => navigate(navigateTo)}
        />
      )}
    </BannerWrapper>
  );
};

export default Banner;
