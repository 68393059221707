import React from 'react';
import { ContactCardAttributeType, Entity_Attribute_Type } from 'graphql/generated/graphql';
import { Containter } from './index.styled';

interface SvgFromJsonProps {
  type?: ContactCardAttributeType | Entity_Attribute_Type;
}

type SvgItem = {
  [key: string]: SvgItem[];
} & {
  $: {
    [key: string]: string;
  };
};

const IconFromAttributeType: React.FC<SvgFromJsonProps> = ({ type }) => {
  const backgroundStyle = type?.props?.style?.default || '';
  const svgItem: SvgItem = type?.props?.svg?.json?.svg;

  if (!svgItem) {
    return <></>;
  }

  const snakeToCamel = (str: string) => {
    if (str.includes('-') || str.includes('_')) {
      return str.toLowerCase().replace(/([-_][a-z])/g, group => group.toUpperCase().replace('-', '').replace('_', ''));
    }
    return str;
  };

  const toParams = (item: SvgItem): Record<string, string> => {
    const params: any = {};
    Object.keys(item.$).forEach(key => {
      const value = item.$[key];
      params[snakeToCamel(key)] = value;
    });
    return params;
  };

  const renderDefs = (svg: SvgItem): JSX.Element[] =>
    svg.defs?.map((def, index) => (
      <defs key={index}>
        {def.filter?.map((filter, i) => (
          <filter key={i} {...toParams(filter)}>
            {filter.feBlend?.map((feBlend, j) => (
              <feBlend key={j} {...toParams(feBlend)} />
            ))}
            {filter.feColorMatrix?.map((feColorMatrix, j) => (
              <feColorMatrix key={j} {...toParams(feColorMatrix)} />
            ))}
            {filter.feFlood?.map((feFlood, j) => (
              <feFlood key={j} {...toParams(feFlood)} />
            ))}
            {filter.feOffset?.map((feOffset, j) => (
              <feOffset key={j} {...toParams(feOffset)} />
            ))}
            {filter.feGaussianBlur?.map((feGaussianBlur, j) => (
              <feGaussianBlur key={j} {...toParams(feGaussianBlur)} />
            ))}
            {filter.feComposite?.map((feComposite, j) => (
              <feComposite key={j} {...toParams(feComposite)} />
            ))}
          </filter>
        ))}
        {def.linearGradient?.map((linearGradient, i) => (
          <linearGradient key={i} {...toParams(linearGradient)}>
            {linearGradient.stop?.map((stop, j) => (
              <stop key={j} {...toParams(stop)} />
            ))}
          </linearGradient>
        ))}
        {def.radialGradient?.map((radialGradient, i) => (
          <radialGradient key={i} {...toParams(radialGradient)}>
            {radialGradient?.stop?.map((stop, j) => (
              <stop key={j} {...toParams(stop)} />
            ))}
          </radialGradient>
        ))}
        {def.clipPath?.map((clipPath, i) => (
          <clipPath key={i} {...toParams(clipPath)}>
            {clipPath.rect?.map((rect, j) => (
              <rect key={j} {...toParams(rect)} />
            ))}
          </clipPath>
        ))}
      </defs>
    ));

  const renderGPaths = (svg: SvgItem): JSX.Element[] =>
    svg.g?.map((g, index) => (
      <g key={index} {...toParams(g)}>
        {renderPaths(g)}
      </g>
    ));
  const renderPaths = (svg: SvgItem): JSX.Element[] =>
    svg.path?.map((path, index) => <path key={index} {...toParams(path)} />);

  return (
    <Containter
      className="icon-background shadow-lg"
      style={{ height: '45px', width: '45px', background: `${backgroundStyle}` }}
    >
      <svg {...toParams(svgItem)} version="1" className="scale-75">
        {renderDefs(svgItem)}
        {renderGPaths(svgItem)}
        {renderPaths(svgItem)}
      </svg>
    </Containter>
  );
};

export default IconFromAttributeType;
