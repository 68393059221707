import React from 'react';
import { Section } from './index.styled';
import { Container } from 'components/container';
import { GradiantText } from 'components/text';

const Services = () => {
  return (
    <Section>
      <Container>
        <div className="grid text-center">
          <div className="col-12 md:col-8 md:col-offset-2">
            <GradiantText>PERFORMANCE</GradiantText>
            <h6>WE ARE YOUR AGENT FOR DIVIDENDS AND REBATES FOR YOUR IDENTITY (COMING SOON).</h6>
          </div>
          <div className="col-12 md:col-8 md:col-offset-2">
            <GradiantText>PROTECTION</GradiantText>
            <h6>We are your Agent for creating a smart contract to store your data in our Blockchain.</h6>
          </div>
          <div className="col-12 md:col-8 md:col-offset-2">
            <GradiantText>PRIVACY</GradiantText>
            <h6>
              We are your Agent for creating a unique hash that will guarantee your anonymity in crwdworld blockchain
              ecosystem.
            </h6>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default Services;
