import React from 'react';
import { Breadcrumbs } from 'components/breadcrumbs';
import ClaimIdForm from 'components/claim-id-form/ClaimIdForm';
import PreLoading from 'components/pre-loading';
import Benefits from 'components/benefits';
import { Section } from './index.styled';
import data from './data.json';

const Points = [
  {
    text: '93% of Americans considered it important to be able to control who could access their personal data.',
  },
  {
    text: '86% of US citizens have attempted to somehow remove or decrease their digital online footprint.',
  },
  {
    text: 'Around 81% of Americans express concerns regarding companies collecting their private data.',
  },
  {
    text: 'Your digital rights management is easier to maintain when you are anonymous.',
  },
  {
    text: 'Anonymity is a human right.',
  },
];

const PrivacyPage = () => {
  return (
    <Section>
      <PreLoading label="PRIVACY" />
      <Breadcrumbs
        className="breadcrumbs"
        label="PRIVACY"
        text="Claim your Online Identity and begin to reap rewards."
      />
      <div className="grid">
        <div className="col-12 md:col-10 md:col-offset-1">
          <img src="/assets/images/Privacy_wall.png" width="100%" alt="Privacy Wall" />
          <div className="grid">
            <div className="col-10 col-offset-1">
              <h4>How will your identity be anonymized online?</h4>
              <h6 className="my-4">
                Online anonymity is important, Personal data is considered among the most valuable commodities on the
                internet, that's why hackers go out of their way to obtain it. Being anonymous prevents hackers from
                getting access to sensitive information such as personal data, credit card transactions, passwords, and
                banking information.
              </h6>
              <p className="my-5">
                Our crwdid is better than a paper business card - you can choose what you want to share. A crwdid
                ensures you can choose your level of anonymity.
              </p>
              <h6>ENJOY PERSONAL ANONYMITY IN AND OUT OF THE BLOCKCHAIN ECOSYSTEM!</h6>
              <p className="my-5">
                Enjoy anonymity in crwdmarket, on your wallet on crwdcapital, and investing in crwdfinance. You choose
                when, where and how you want to share your data.
              </p>

              <ul>
                {Points.map((point, i) => (
                  <li key={i}>
                    <span>0{i + 1}</span> {point.text}
                  </li>
                ))}
              </ul>

              <div className="quotes text-center">
                <p>CLAIM YOUR ONLINE CONTACT CARD TODAY!</p>
              </div>

              <Benefits data={data} />

              <ClaimIdForm />
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};
export default PrivacyPage;
