import React from 'react';
import { Section } from './index.styled';
import { Container } from 'components/container';

const ProjectDetails = () => {
  return (
    <Section>
      <Container fluid>
        <div className="grid">
          <div className="col-12 md:col-3">
            <img src="/assets/images/1_share.png" alt="share" />
          </div>
          <div className="col-12 md:col-3">
            <img src="/assets/images/first_impressions.png" alt="first impressions" />
          </div>
          <div className="col-12 md:col-3">
            <img src="/assets/images/best_brand.png" alt="best brand" />
          </div>
          <div className="col-12 md:col-3">
            <img src="/assets/images/join_us.png" alt="join us" />
          </div>
          <div className="col-12">
            <img className="big" src="/assets/images/7.jpg" alt="logo" />
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default ProjectDetails;
