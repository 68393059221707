import React from 'react';
import Button from 'components/button';
import { Container } from 'components/container';
import { BGTitleText } from 'components/text';
import { ParallaxBanner } from './index.styled';
import { useNavigate } from 'react-router-dom';

const CallAction = props => {
  const navigate = useNavigate();

  return (
    <ParallaxBanner
      layers={[
        {
          image: '/assets/images/patrn.svg',
          speed: 30,
        },
      ]}
    >
      <Container className="container">
        <div className="grid m-0 align-items-center">
          <div className="col-12 md:col-9">
            <h5>{props.heading}</h5>
            <span className="heading">
              CLAIM YOUR <br />
              <BGTitleText>IDENTITY TODAY</BGTitleText> .
            </span>
          </div>

          <div className="col-12 md:col-3">
            <Button
              label="CLAIM YOUR IDENTITY!"
              className="p-button-outlined light"
              onClick={() => navigate('/p/claim')}
            />
          </div>
        </div>
      </Container>
    </ParallaxBanner>
  );
};

export default CallAction;
