import React from 'react';
import { Breadcrumbs } from 'components/breadcrumbs';
import CallAction from 'components/call-action';
import PreLoading from 'components/pre-loading';
import Clients from './clients';
import Intro from './intro';
import Services from './services';
import Summary from './summary';
import TeamCRV from './team-crv';
import TestimonialBlock from './testimonial-block';

const AboutPage = () => {
  return (
    <div className="about-section">
      <PreLoading label="ABOUT" />
      <Breadcrumbs label="ONLINE CONTACT MANAGEMENT" />
      <div className="grid" style={{ marginLeft: 0, marginRight: 0, marginTop: '-70px' }}>
        <div className="flex justify-content-center col-12 md:col-8 md:col-offset-2">
          <img className="banner-img" src="/assets/images/about.png" width="100%" alt="Blockchain EcoSystem" />
        </div>
      </div>
      <Intro />
      <Services />
      <TestimonialBlock />
      <TeamCRV />
      <Summary />
      <Clients />
      <CallAction />
    </div>
  );
};
export default AboutPage;
