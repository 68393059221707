import React, { useEffect } from 'react';
import useLocalStorage from 'services/useLocalStorage';
import { ThemeProvider as ThemeProviders } from 'styled-components';

interface CustomThemeType {
  isDark: boolean;
}

const themes = {
  dark: {
    isDark: true,
  },
  light: {
    isDark: false,
  },
};

const initialState = {
  theme: themes.light,
  setDark: (val: boolean) => {},
};
const ThemeContext = React.createContext(initialState);

const ThemeProvider = ({ children }) => {
  const [isDarkFromLocalStroage, setIsDarkInLocalStorage] = useLocalStorage<boolean>('isDark', true);

  const setDark = (val: boolean) => {
    setIsDarkInLocalStorage(val);
  };

  useEffect(() => {
    document
      .getElementById('primeThemeCSS')
      ?.setAttribute(
        'href',
        isDarkFromLocalStroage ? '/assets/theme/mdc-dark-indigo/theme.css' : '/assets/theme/mdc-light-indigo/theme.css',
      );
  }, [isDarkFromLocalStroage]);

  const theme = isDarkFromLocalStroage ? themes.dark : themes.light;

  return (
    <ThemeProviders theme={theme}>
      <ThemeContext.Provider value={{ theme, setDark }}>{children}</ThemeContext.Provider>
    </ThemeProviders>
  );
};

export { ThemeProvider, ThemeContext };
export type { CustomThemeType };
