
import styled from 'styled-components';

export const Section = styled.section`
  // background: var(--surface-100);
  min-height: 100vh;
  width: 100%;

  .header-wrapper {
    margin: 0;
    padding: 1.2rem 1.2rem;
    justify-content: space-between;
    align-items: center;
    // position: sticky;
    top: 0px;
  }

  .action-wrapper {
    margin: 0;
    padding: 1.2rem 1.2rem 20px;
    justify-content: space-between;
    align-items: center;
    // position: sticky;
    top: 83px;

    p {
      margin: 0;
      padding: 0 150px 0 0;
      font-weight: 300;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: -0.03em;

      span {
        font-weight: 700;
        letter-spacing: -0.04em;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  .main {
    width: 100%;
    padding: 1.2rem 1.2rem 20px;
    border-radius: 40px 40px 0 0;
    transition: all 0.3s ease;
    // background-color: var(--surface-section);
    position: relative;
    z-index: 3;
    margin-top: 15px;
    // box-shadow: 0 -56px 80px rgb(110 135 171 / 7%), 0 -28.0273px 40.0391px rgb(110 135 171 / 5%),
    //   0 -16.8824px 24.1177px rgb(110 135 171 / 5%), 0 -10.8191px 15.4559px rgb(110 135 171 / 4%),
    //   0 -7.01207px 10.0172px rgb(110 135 171 / 4%), 0 -4.41402px 6.30574px rgb(110 135 171 / 3%),
    //   0 -2.53613px 3.62304px rgb(110 135 171 / 2%), 0 -1.11622px 1.59459px rgb(110 135 171 / 2%);
    min-height: 800px;

    .profile-section {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      border-radius: 15px;
      transition: all 0.3s ease;
      margin-bottom: 60px;

      .banner-upload {
        width: 100%;
        height: 137px;
        border-radius: 15px;
        border: 2px solid var(--surface-border);
        background: linear-gradient(180deg, var(--surface-hover), var(--surface-border));
        overflow: hidden;

        .banner-image {
          object-fit: cover;
          width: 101%;
          height: 101%;
        }

        .p-fileupload {
          position: absolute;
          bottom: 5px;
          right: 5px;
          box-shadow: 0px 0px 20px rgba(110, 135, 171, 0.5);
        }
      }

      .profile-upload {
        background: var(--surface-ground);
        bottom: -50px;
        left: calc(50% - 60px);
        width: 120px;
        height: 120px;
        position: absolute;
        border: 2px solid var(--surface-border);
        border-radius: 50%;
        // overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        .profile-image {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
          object-position: 50% 50%;
          display: block;
        }

        .p-fileupload {
          position: absolute;
          bottom: 5px;
          right: -3px;
          box-shadow: 0px 0px 20px rgba(110, 135, 171, 0.5);

          .p-fileupload-choose {
            padding: 0.486rem 0.5rem;
          }
        }
      }
    }
    .crwd-score-section {
      display: flex;
      gap: 180px;
      margin: -50px 0 50px 0;
    }

    .box {
      p {
        font-weight: 600;
        font-size: 15px;
        line-height: 17px;
        margin-bottom: 0;
      }

      .grid {
        grid-gap: 8px;
        border-radius: 9px;
        width: 100%;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 10px 8px;
        transition: all 0.3s ease;
        .grid {
          width: auto;
          background: transparent;
          box-shadow: none;
          padding: 0;
        }
      }

      &.primaryColor {
        .field-radiobutton {
          margin: 0;

          .p-radiobutton {
            display: none;
          }

          .color-circle {
            width: 25px;
            height: 25px;
            position: relative;
            border-radius: 50%;

            &:hover {
              box-shadow: 0px 0px 20px rgba(110, 135, 171, 0.55);
            }

            &:active {
              box-shadow: 0px 0px 20px rgba(110, 135, 171, 1);
            }

            &.selected {
              border: 2px solid var(--text-color);
            }
          }
        }
      }

      &.theme {
        .field-radiobutton {
          margin: 0;
          border: 1.5px solid var(--surface-border);
          padding: 5px 15px 5px 10px;
          border-radius: 4px;
          position: relative;
          cursor: pointer;

          label {
            margin-left: 20px;
            font-weight: 500;
            text-transform: capitalize;
          }

          &.bright {
          }
          &.dim,
          &.dark {
            label {
              color: #fff;
            }

            .p-radiobutton-boxs {
              &:not(.p-highlight) {
                border-color: #fff;
              }
            }
          }

          .p-radiobutton {
            width: 10px;
            height: 10px;

            .p-radiobutton-box {
              width: 10px;
              height: 10px;
              background: transparent;

              &:not(.p-highlight) {
                border-color: var(--primary-color);
              }
            }
          }
        }
      }

      &.icon {
        .field-radiobutton {
          margin: 0;

          &.selected {
            label {
              .p-avatar {
                border: 1px solid #000;
              }
            }
          }

          label {
            margin: 0;

            .p-avatar {
              border: 1px solid transparent;
            }
          }

          .p-radiobutton {
            display: none;
          }
        }
      }
    }
  }

  @media (max-width: 576px) {
    .header-wrapper {
      padding: 1.2rem 0;
    }

    .action-wrapper {
      padding: 1.2rem 0 20px;

      p {
        padding: 0;
      }
    }

    .main {
      width: 100%;
      padding: 1.2rem 0 20px;

      .crwd-score-section {
        margin-top: 50px;
        gap: 15px;
        flex-direction: column;
      }
    }
  }
`;
