import React from 'react';
import { StyledSpinner } from './index.styled';

export const Spinner: React.FC = () => {
  return (
    <div className="flex flex-column gap-2">
      <StyledSpinner style={{ width: '50px', height: '50px' }} strokeWidth="4" fill="var(--primary-color)" />
      Loading
    </div>
  );
};
