import React from 'react';
import { Container } from 'components/container';
import { Button } from 'primereact/button';
import { FaPlay } from 'react-icons/fa';
import PlayerDialog from 'components/player-modal';
import { ParallaxBanner } from './index.styled';
import { useState } from 'react';

const VideoWrapper = () => {
  const [openPlayer, setOpenPlayer] = useState(false);

  return (
    <ParallaxBanner
      layers={[
        {
          image: '/assets/images/bg.jpg',
          speed: 10,
        },
      ]}
    >
      <Container className="container">
        <Button onClick={() => setOpenPlayer(true)} className="p-button-rounded">
          <FaPlay />
        </Button>
        <PlayerDialog show={openPlayer} videoUrl="6306709017112" onHide={() => setOpenPlayer(false)} />
      </Container>
    </ParallaxBanner>
  );
};

export default VideoWrapper;
