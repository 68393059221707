import React from 'react';
import { createSearchParams } from 'react-router-dom';
import Button from 'components/button';
import Input from 'components/input';
import { useForm } from 'react-hook-form';
import { useAuthContext } from 'utils/contexts/AuthContext';

type inputIdAndLabel = {
  id: string;
  label: string;
};

interface IdFormProps {
  handle?: string;
  showFields?: boolean;
  formTitle?: string;
  inputFieldLabels?: inputIdAndLabel[];
  redirectUrl?: string;
}

const portalUrl = process.env.REACT_APP_PORTAL_URL;

export default function ClaimIdForm({
  handle,
  showFields = false,
  formTitle = 'Claim your ID today:',
  inputFieldLabels = [
    { id: 'firstname', label: 'First Name' },
    { id: 'lastname', label: 'Last Name' },
    { id: 'email', label: 'E-mail' },
  ],
  redirectUrl = portalUrl,
}: IdFormProps) {
  const formState = useForm();
  const { control, handleSubmit } = formState;
  const { affiliateCode } = useAuthContext()

  const handleSave = (data: any): void => {
    const params = {
      ...data,
      view: 'signup',
    };
    const handleOrCode = affiliateCode || handle;
    if (handleOrCode) {
      params.handle = handleOrCode;
    } 
    window.location.replace(`${redirectUrl}?${createSearchParams(params)}`);
  };

  return (
    <>
      <div className="grid">
        <div className="col-12 md:col-6 md:col-offset-3 text-center ">
          <h4 className="mb-4 uppercase">{formTitle}</h4>
          <form onSubmit={handleSubmit(handleSave)}>
            {showFields && inputFieldLabels.map(input => {
              return (
                <div key={input.id}>
                  <Input
                    name={input.id}
                    label={input.label}
                    control={control}
                    className="mb-3"
                  />
                </div>
              );
            })}

            <Button label="START TODAY" className="light" />
          </form>
        </div>
      </div>
    </>
  );
}
