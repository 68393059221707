import React from 'react';
import Banner from 'components/banner';
import { LargeText, SubText } from 'components/text';
import { Section } from './index.styled';
import Button from 'components/button';
import Input from 'components/input';
import { useForm, useFormState } from 'react-hook-form';
import { useEffect, useState } from 'react';

const LoginPage = () => {
  const { control, watch, handleSubmit } = useForm({ defaultValues: { email: '', password: '' } });
  const { dirtyFields, errors } = useFormState({ control });

  useEffect(() => {
    const subscription = watch(value => console.log(value));
    return () => subscription.unsubscribe();
  }, [watch]);

  const [emailServerValidation] = useState<boolean>(false);
  const [passwordServerValidation] = useState<boolean>(false);

  const onSubmit = data => {
    console.log(data);

    // reset();
  };

  return (
    <Section>
      <Banner src="/assets/images/banner_2.jpg" navigateTo="/" />
      <div className="m-4">
        <LargeText>email + password</LargeText>
        <SubText>login with email</SubText>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            className="mt-2"
            placeholder="email"
            name="email"
            control={control as any}
            rules={{
              required: 'Email is required.',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Invalid email address. E.g. example@email.com',
              },
            }}
            icon={
              emailServerValidation
                ? 'pi-spin pi-spinner'
                : errors.email
                ? 'pi-times-circle error'
                : !errors.email && dirtyFields.email
                ? 'pi-check-circle success'
                : ''
            }
          />
          {errors['email'] && <small className="p-error">{errors['email'].message}</small>}

          <Input
            className="mt-3"
            placeholder="password"
            name="password"
            control={control as any}
            rules={{
              required: 'password is required.',
            }}
            icon={
              passwordServerValidation
                ? 'pi-spin pi-spinner'
                : errors.password
                ? 'pi-times-circle error'
                : !errors.password && dirtyFields.password
                ? 'pi-check-circle success'
                : ''
            }
          />
          {errors['password'] && <small className="p-error">{errors['password'].message}</small>}
          <Button label="Login" icon="pi pi-arrow-right" iconPos="right" className="p-button-secondary mt-4" />
        </form>
        <Button label="Forgot Password?" className="p-button-link mt-5 p-0" />
      </div>
    </Section>
  );
};

export default LoginPage;
