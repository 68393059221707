import React from 'react';
import PrimeReact from 'primereact/api';
// import "primereact/resources/themes/md-light-indigo/theme.css";
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import ScrollToTop from './utils/ScrollToTop';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import MainPage from 'pages/main';
import SignupPage from 'pages/signup';
import LoginPage from 'pages/login';
import OnBoardingFirstPage from 'pages/signup/onboarding/onboardingFirst';
import OnBoardingSecondPage from 'pages/signup/onboarding/onboardingSecond';
import HomePage from 'pages/home';
import Header from 'components/header';
import Footer from 'components/footer';
import SetupPage from 'components/contact-card-details/setup';
import ServicePage from 'components/contact-card-details/setup/service';
import AboutPage from 'pages/about';
import ClaimPage from 'pages/claim';
import PerformancePage from 'pages/performance';
import ProtectionPage from 'pages/protection';
import PrivacyPage from 'pages/privacy';
import ContactPage from 'pages/contact';
import ContactCardPage from 'pages/contact-card-page';
import { FooterModeProvider } from 'utils/contexts/useFooterMode';
import { AuthProvider } from 'utils/contexts/AuthContext';

function App() {
  // active ripple effect
  PrimeReact.ripple = true;

  return (
    <>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <AuthProvider>
          <FooterModeProvider>
            <ScrollToTop />
            <div className="app" style={{ display: 'flex', flexDirection: 'column' }}>
              <Header />
              <div className="wrap">
                <Routes>
                  <Route path="/p/contact" element={<ContactPage />} />
                  <Route path="/p/performance" element={<PerformancePage />} />
                  <Route path="/p/protection" element={<ProtectionPage />} />
                  <Route path="/p/privacy" element={<PrivacyPage />} />
                  <Route path="/p/about" element={<AboutPage />} />
                  <Route path="/p/claim" element={<ClaimPage />} />
                  <Route path="/p/setup" element={<SetupPage />} />
                  <Route path="/:handle" element={<ContactCardPage />} />
                  <Route path="/p/service" element={<ServicePage />} />
                  <Route path="/p/onboarding/1" element={<OnBoardingFirstPage />} />
                  <Route path="/p/onboarding/2" element={<OnBoardingSecondPage />} />
                  <Route path="/p/signup" element={<SignupPage />} />
                  <Route path="/p/login" element={<LoginPage />} />
                  <Route path="/p/auth" element={<MainPage />} />
                  <Route path="" element={<HomePage />} />
                  <Route path="*" element={<Navigate to="" />} />
                </Routes>
              </div>
              <Footer />
            </div>
          </FooterModeProvider>
        </AuthProvider>
      </BrowserRouter>
    </>
  );
}

export default App;
