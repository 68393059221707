import styled from 'styled-components';

export const CrwdScoreSection = styled.div`
  display: flex;
  gap: 6px;
  position: relative;

  h6 {
    font-size: 9px;
    letter-spacing: 2px;
    padding: 6px 4px;
    min-width: 94px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: bold;
  }

  @media (max-width: 576px) {
    flex-wrap: wrap;
  }
`;

export const CrwdLabel = styled.div`
  width: 100%;
  text-align: center;
  border-bottom: 1px solid var(--primary-color);
  line-height: 0.1em;
  margin: 10px 0 20px;
  position: absolute;
  bottom: -42px;
  left: 0;
  span {
    color: var(--primary-color);
    text-transform: uppercase;
    padding: 8px;
    background: var(--surface-ground);
  }
`;
