import React from 'react';
import Banner from 'components/banner';
import { LargeText, SubText } from 'components/text';
import { Section } from './index.styled';
import Button from 'components/button';
import Input from 'components/input';
import { useForm, useFormState } from 'react-hook-form';
import Dropdown from 'components/dropdown';
import { useNavigate } from 'react-router-dom';

const OnBoardingFirstPage = () => {
  const navigate = useNavigate();

  const identities = [
    { name: 'business', code: 'business' },
    { name: 'male', code: 'male' },
    { name: 'female', code: 'female' },
    { name: 'non-binary', code: 'non-binary' },
    { name: 'prefer not to answer', code: 'prefer not to answer' },
  ];

  const month = [
    { name: 'january', code: 'january' },
    { name: 'february', code: 'february' },
    { name: 'march', code: 'march' },
    { name: 'april', code: 'april' },
    { name: 'may', code: 'may' },
    { name: 'june', code: 'june' },
    { name: 'july', code: 'july' },
    { name: 'august', code: 'august' },
    { name: 'september', code: 'september' },
    { name: 'october', code: 'october' },
    { name: 'november', code: 'november' },
    { name: 'december', code: 'december' },
  ];

  const day = [];
  for (let i = 1; i <= 31; i++) {
    day.push({ name: i, code: i });
  }

  const year = [];
  for (let i = 1906; i <= 2006; i++) {
    year.push({ name: i, code: i });
  }

  const { control, handleSubmit } = useForm({
    defaultValues: { name: '', identity: null, month: null, day: null, year: null },
  });
  const { errors } = useFormState({ control });

  const onSubmit = data => {
    console.log(data);
    navigate('/onboarding/2');
    // reset();
  };

  return (
    <Section>
      <Banner src="/assets/images/banner_3.jpg" />
      <div className="m-4">
        <LargeText>name.</LargeText>
        <SubText>so we know what to call you</SubText>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            className="mt-2"
            placeholder="name"
            name="name"
            control={control as any}
            rules={{
              required: 'Name is required.',
            }}
          />
          {errors['name'] && <small className="p-error">{errors['name'].message}</small>}

          <LargeText className="mt-4">identity.</LargeText>
          <SubText>for a customized experience</SubText>
          <Dropdown
            className="mt-2"
            name="identity"
            control={control as any}
            rules={{
              required: 'Identity is required.',
            }}
            options={identities}
            optionLabel="name"
            placeholder="Choose(optional)"
          />
          {errors['identity'] && <small className="p-error">{errors['identity'].message}</small>}

          <LargeText className="mt-4">birthday.</LargeText>
          <SubText>enter date of birth</SubText>
          <div className="grid mt-1">
            <div className="col-5">
              <Dropdown
                name="month"
                control={control as any}
                rules={{
                  required: 'Month is required.',
                }}
                options={month}
                optionLabel="name"
                placeholder="month"
              />
              {errors['month'] && <small className="p-error">{errors['month'].message}</small>}
            </div>
            <div className="col-7">
              <div className="grid">
                <div className="col-6">
                  <Dropdown
                    name="day"
                    control={control as any}
                    rules={{
                      required: 'Day is required.',
                    }}
                    options={day}
                    optionLabel="name"
                    placeholder="day"
                  />
                  {errors['day'] && <small className="p-error">{errors['day'].message}</small>}
                </div>
                <div className="col-6">
                  <Dropdown
                    name="year"
                    control={control as any}
                    rules={{
                      required: 'Year is required.',
                    }}
                    options={year}
                    optionLabel="name"
                    placeholder="year"
                  />
                  {errors['year'] && <small className="p-error">{errors['year'].message}</small>}
                </div>
              </div>
            </div>
          </div>

          <Button
            label="Continue"
            icon="pi pi-arrow-right"
            iconPos="right"
            className="p-button-secondary btn-shadow mt-4"
          />
        </form>
      </div>
    </Section>
  );
};

export default OnBoardingFirstPage;
