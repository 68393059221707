import React from 'react';
import { Section } from './index.styled';
import { Container } from 'components/container';
import Button from 'components/button';
import { BGTitleText } from 'components/text';
import { LineDivider } from 'components/LineDivider';
import { Link } from 'react-scroll';

const Banner = () => {
  return (
    <Section>
      <Container>
        <div className="grid">
          <div className="col-12 md:col-6">
            <BGTitleText>PERFORMANCE | PROTECTION | PRIVACY</BGTitleText>
            <h1>YOUR ONLINE IDENTITY AGENCY</h1>
            <p className="text-justify md:col-11">
              Welcome to crwdid! We are your trusted digital identity agency in an untrusted world. Claim your own
              blockchain contact card and gain entrance to crwdworld. Sign up for your decentralized and distributed
              identification today!
            </p>
            <Link to="home-stats" spy={true} smooth={true} offset={-100} duration={700}>
              <Button label="About Us" className="p-button-outlined light uppercase" />
            </Link>
          </div>
          <div className="col-12 md:col-6">
            <div className="img-wrapper">
              <img src="/assets/images/crwdid_color.png" alt="" />
            </div>
          </div>
        </div>
      </Container>
      <LineDivider />
    </Section>
  );
};

export default Banner;
