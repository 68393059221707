import React from 'react';
import parse from 'html-react-parser';
import { Section } from './index.styled';
import { Container } from 'components/container';

interface Props {
  index: number;
  label: string;
  text: string;
  itemList?: Array<string>;
}
const Intro = ({ index, label, text, itemList }: Props) => {
  return (
    <Section>
      <Container>
        <div className="grid">
          <div className="col-12 md:col-3">
            <h6>
              <span>{index}</span>
              {label}
            </h6>
          </div>
          <div className="col-12 md:col-8 md:col-offset-1">
            {parse(text)}

            {itemList && (
              <ul>
                {itemList.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default Intro;
