import styled from 'styled-components';

export const Section = styled.section`
  min-height: 100vh;
  padding: 50px 0;
  position: relative;

  h1 {
    margin: 10px 0 !important;
    font-weight: 600 !important;
    font-size: 55px;
    line-height: 65px;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 2;
    margin: 0;
    margin-bottom: 30px !important;
  }

  .img-wrapper {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: -15px;
      left: -15px;
      right: -15px;
      bottom: -15px;
      background: #fff;
      border-radius: 15px;
      -webkit-transform: rotate(-3deg);
      -ms-transform: rotate(-3deg);
      transform: rotate(-3deg);
      opacity: 0.08;
    }

    img {
      width: 100%;
      border-radius: 15px;
      transform: rotate(3deg);
      position: relative;
      z-index: 2;
    }

    &:after {
      content: '';
      position: absolute;
      top: -5px;
      left: -5px;
      right: -5px;
      bottom: -5px;
      background: linear-gradient(to right, var(--primary-color), var(--help-color), var(--danger-color));
      border-radius: 15px;
      -webkit-transform: rotate(-3deg);
      -ms-transform: rotate(-3deg);
      transform: rotate(-3deg);
    }
  }
  @media (max-width: 576px) {
    .grid {
      gap: 20px;
    }
    h1 {
      font-size: 40px;
      line-height: 50px;
    }
    p {
      font-size: 14px;
    }
  }
`;
