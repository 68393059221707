
import Slider from 'react-slick';
import styled from 'styled-components';

export const Section = styled.section`
  // padding: 100px 0;
  overflow: hidden;

  @media (max-width: 576px) {
  }
`;

export const Item = styled.div`
  // width: 558px !important;
  text-align: left;
  padding: 80px 40px;
  transform: scale(0.8);
  background: linear-gradient(126.47deg, #18191d 6.69%, rgba(2, 2, 30, 0) 85.43%);
  transition: all 0.4s;
  display: flex !important;
  align-items: center;

  .author {
    min-width: 120px;

    .img {
      width: 80px;
      height: 80px;
      border: 7px solid #25262c;
      border-radius: 50%;
      overflow: hidden;
      margin-bottom: 20px;

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    .author-name {
      margin-bottom: 5px;
      font-weight: 600;
      font-size: 15px;
    }

    .author-details {
      font-size: 13px;
      padding: 0px;
    }
  }

  p {
    color: var(--text-color);
    font-size: 28px;
    font-weight: 600;
    padding-left: 30px;
    margin-left: 30px;
    line-height: 2;
    border-left: 1px solid rgba(255, 255, 255, 0.1);
  }

  @media (max-width: 576px) {
    flex-direction: column;
    padding: 10px;
    text-align: center;

    .author {
      min-width: auto;

      .img {
        margin: 0 auto 20px;
      }
    }

    p {
      color: var(--text-color);
      font-size: 19px;
      font-weight: 500;
      padding-left: 0px;
      padding-top: 10px;
      margin-left: 0px;
      line-height: 2;
      border: none;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
`;

export const Slide = styled(Slider)`
  padding: 0;

  .slick-track {
    .slick-center {
      ${Item} {
        transform: scale(1);
      }
    }
  }

  .slick-arrow {
    height: auto;
    width: auto;
    opacity: 0;
    transition: all 0.6s;
    z-index: 1;

    &:before {
      font-size: 40px;
    }
  }

  .slick-prev {
    left: -10%;
  }

  .slick-next {
    right: -10%;
  }

  &:hover {
    .slick-arrow {
      opacity: 1;
    }

    .slick-prev {
      left: 24%;
    }

    .slick-next {
      right: 24%;
    }
  }

  @media (max-width: 576px) {
    &:hover {
      .slick-arrow {
        opacity: 0;
      }
    }
  }
`;
