import React from 'react';
import Banner from 'components/banner';
import { LargeText, SubText } from 'components/text';
import { Section } from './index.styled';
import Button from 'components/button';
import Input from 'components/input';
import { useForm, useFormState } from 'react-hook-form';
import { useState } from 'react';
import CheckChips from 'components/checkChips';

const OnBoardingSecondPage = () => {
  const [userNameServerValidation] = useState<boolean>(false);

  const { control, register, handleSubmit } = useForm({
    defaultValues: { username: '', niche: null },
  });
  const { errors, dirtyFields } = useFormState({ control });

  const onSubmit = data => {
    console.log(data);

    // reset();
  };

  const niche = [
    'Creative',
    'Real Estate',
    'Influencer',
    'Music',
    'Athlete',
    'Business',
    'Social',
    'Video Creator',
    'Artist',
    'Education',
    'Entrepreneur',
    'Gaming',
    'Blogging',
    'Photography',
    'Beauty',
    'Writer',
  ];

  return (
    <Section>
      <Banner src="/assets/images/banner_4.jpg" />
      <div className="m-4">
        <LargeText>one link.</LargeText>
        <SubText>to share all your information</SubText>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input
            className="mt-2 username-input"
            placeholder="username"
            name="username"
            control={control as any}
            rules={{
              required: 'Username is required.',
            }}
            icon={
              userNameServerValidation
                ? 'pi-spin pi-spinner'
                : errors.username
                ? 'pi-times-circle error'
                : !errors.username && dirtyFields.username
                ? 'pi-check-circle success'
                : ''
            }
          >
            <p className="username-prefix">crwdid.cards/</p>
          </Input>
          {errors['username'] && <small className="p-error">{errors['username'].message}</small>}

          <LargeText className="mt-4">pick a niche.</LargeText>
          <SubText>for a customized experience</SubText>
          <div className="grid m-0 mt-2">
            {niche.map((item, i) => (
              <CheckChips key={i} id={'input' + i} name={`niche[${i}]`} label={item} register={register} />
            ))}
          </div>

          <Button
            label="Create your CRWDID"
            icon="pi pi-arrow-right"
            iconPos="right"
            className="p-button-secondary btn-shadow mt-5"
          />
        </form>
      </div>
    </Section>
  );
};

export default OnBoardingSecondPage;
