
import styled from 'styled-components';

export const Section = styled.section`
  background: var(--surface-section);
  padding-bottom: 20px;
  min-height: 100vh;

  img {
    width: 100%;
    height: 200px;
  }

  .p-divider {
    margin: 50px 0;

    &:before {
      width: 80%;
      height: 13px;
      left: 10%;
      border-top: 1.5px solid var(--text-color);
    }
  }

  @media (max-width: 576px) {
  }
`;
