
import styled from 'styled-components';

export const Section = styled.section`
  .breadcrumbs {
    background: transparent;
    padding-top: 230px;

    h3 {
      font-size: 80px;
      font-weight: 600;
    }
  }

  .main {
    padding: 120px 0;
    background: var(--surface-section);

    h3 {
      font-weight: 700;
      line-height: 1.4;
      font-size: 30px;
      display: inline-block;
      margin-bottom: 50px;
    }

    h4 {
      margin-top: 60px;
      font-weight: 700;
      font-size: 30px;
    }

    textarea {
      width: 100%;
    }

    h5 {
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 2px;
      margin-bottom: 10px;

      &.second {
        margin-top: 40px;
      }
    }

    p {
      font-size: 14px;
      font-weight: 300;
      margin: 10px 0;
    }

    .social {
      margin: 50px 5px 0px !important;

      button {
        box-shadow: none;
        background: rgba(255, 255, 255, 0.01);
        padding: 14px 15px;
      }
    }
  }

  .map-wrapper {
    width: 100%;
    height: 60vh;

    > div {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 576px) {
    .breadcrumbs {
      padding-top: 200px;

      h3 {
        font-size: 30px;
      }
    }

    .grid {
      gap: 30px;
    }
  }
`;
