import React from 'react';
import PreLoading from 'components/pre-loading';
import Banner from './banner';
import Benefits from '../../components/benefits';
import Services from './services';
import Status from './status';
import Testimonials from './testimonials';
import UniversalAccess from './universal-access';
import data from './data.json';
import CallAction from 'components/call-action';

const HomePage = () => {
  return (
    <>
      <PreLoading label="WELCOME TO crwdid" />
      <Banner />
      <Services />
      <Status />
      <Testimonials />
      <Benefits data={data} text={true} />
      <UniversalAccess />
      <CallAction heading="GET STARTED:" />
    </>
  );
};

export default HomePage;
