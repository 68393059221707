import React from 'react';
import styled from 'styled-components';

export const LineDividerWrapper = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  height: 1px;
  width: 65%;
  background: linear-gradient(to right, var(--primary-color), var(--help-color), var(--danger-color));
`;

export const LineDivider = () => {
  return <LineDividerWrapper className="line-divider" />;
};
