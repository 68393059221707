import React from 'react';
import { LargeText } from 'components/text';
import { Section } from './index.styled';
import Button from 'components/button';
import { useNavigate } from 'react-router-dom';
import { InputSwitch } from 'primereact/inputswitch';
import { Avatar as PrimeAvatar, AvatarShapeType, AvatarSizeType } from 'primereact/avatar';
import { useState } from 'react';
import { FileUpload } from 'primereact/fileupload';
import Input from 'components/input';
import { useForm } from 'react-hook-form';
import GroupContainer from 'components/group-container';
import ContainerAccordion from 'components/ContainerAccordion';
import { RadioButton } from 'primereact/radiobutton';
import { classNames } from 'primereact/utils';
import Avatar from 'components/Avatar';
import Scores from './scores.json';
import CrwdScores from './crwd-score';

const SetupPage = () => {
  const navigate = useNavigate();
  const [checked1, setChecked1] = useState(true);
  const [bannerImage, setBannerImage] = useState<string>('/assets/images/online_identity.png');
  const [profileImage, setProfileImage] = useState<string>('/assets/images/user.png');

  const onBannerImageChange = event => {
    console.log(event.files[0]);
    setBannerImage(URL.createObjectURL(event.files[0]));
  };

  const onProfileImageChange = event => {
    console.log(event.files[0]);
    setProfileImage(URL.createObjectURL(event.files[0]));
  };

  const chooseOptions = {
    icon: 'pi pi-upload',
    iconOnly: true,
    className: 'custom-choose-btn p-button-rounded',
  };

  const { control } = useForm();

  const ContactInfoList = [
    { title: 'Website', icon: '/assets/images/socials/www.png' },
    { title: 'Email', icon: '/assets/images/socials/email.png' },
    { title: 'Call', icon: '/assets/images/socials/phone.png' },
    { title: 'WhatsApp', icon: '/assets/images/socials/whatsapp.png' },
    { title: 'discord', icon: '/assets/images/socials/discord.png' },
    { title: 'WeChat', icon: '/assets/images/socials/wechat.png' },
    { title: 'Telegram', icon: '/assets/images/socials/telegram.png' },
    { title: 'Signal', icon: '/assets/images/socials/signal.png' },
  ];
  const SocialLinks = [
    { title: 'Facebook', icon: '/assets/images/socials/facebook.png' },
    { title: 'Instagram', icon: '/assets/images/socials/instagram.png' },
    { title: 'Twitter', icon: '/assets/images/socials/twitter.png' },
    { title: 'LinkedIn', icon: '/assets/images/socials/linkedin.png' },
    { title: 'YouTube', icon: '/assets/images/socials/youtube.png' },
    { title: 'Snapchat', icon: '/assets/images/socials/snapchat.png' },
    { title: 'TikTok', icon: '/assets/images/socials/tiktok.png' },
    { title: 'Twitch', icon: '/assets/images/socials/twitch.png' },
    { title: 'Pinterest', icon: '/assets/images/socials/pinterest.png' },
    { title: 'Vimeo', icon: '/assets/images/socials/vimeo.png' },
    { title: 'Clubhouse', icon: '/assets/images/socials/clubhouse.png' },
  ];
  const MusicLinks = [
    { title: 'SoundCloud', icon: '/assets/images/socials/soundcloud.png' },
    { title: 'Spotify', icon: '/assets/images/socials/spotify.png' },
    { title: 'Apple Music', icon: '/assets/images/socials/apple-music.png' },
    { title: 'Beatport', icon: '/assets/images/socials/beatport.png' },
    { title: 'Deezer', icon: '/assets/images/socials/deezer.png' },
    { title: 'Tidal', icon: '/assets/images/socials/tidal.png' },
    { title: 'YouTube', icon: '/assets/images/socials/youtubemusic.png' },
    { title: 'Bandcamp', icon: '/assets/images/socials/bandcamp.png' },
    { title: 'Amazon', icon: '/assets/images/socials/amazonmusic.png' },
  ];
  const PaymentLinks = [
    { title: 'PayPal', icon: '/assets/images/socials/paypal.png' },
    { title: 'Venmo', icon: '/assets/images/socials/venmo.png' },
    { title: 'CashApp', icon: '/assets/images/socials/cashapp.png' },
  ];
  const ProductivityLinks = [
    { title: 'Calendly', icon: '/assets/images/socials/calendly.png' },
    { title: 'Notion', icon: '/assets/images/socials/notion.png' },
    { title: 'Google Drive', icon: '/assets/images/socials/googledrive.png' },
    { title: 'Dribble', icon: '/assets/images/socials/dribble.png' },
    { title: 'GitHub', icon: '/assets/images/socials/github.png' },
    { title: 'Behance', icon: '/assets/images/socials/behance.png' },
    { title: 'Medium', icon: '/assets/images/socials/medium.png' },
    { title: 'Booksy', icon: '/assets/images/socials/booksy.png' },
    { title: 'Etsy', icon: '/assets/images/socials/etsy.png' },
    { title: 'Yelp', icon: '/assets/images/socials/yelp.png' },
  ];

  const primaryColors = ['#e23b4d', '#ffd641', '#74d674', '#50ccbd', '#4fa8ed', '#7b61ff', '#414141'];
  const [primaryColor, setPrimaryColor] = useState('#ffd641');

  const themes = ['bright', 'dim', 'dark'];
  const [theme, setTheme] = useState('bright');

  const iconSizes: Array<AvatarSizeType> = ['normal', 'large'];
  const [iconSize, setIconSize] = useState<AvatarSizeType>('normal');

  const [iconShape] = useState<AvatarShapeType>('circle');

  return (
    <Section>
      <div className="header-wrapper grid">
        <LargeText>Setup</LargeText>
        <Button
          icon="pi pi-arrow-right"
          className="p-button-rounded p-button-secondary p-button-outlined"
          onClick={() => {
            // navigate(-1);
          }}
        />
      </div>
      <div className="action-wrapper grid">
        <InputSwitch checked={checked1} onChange={e => setChecked1(e.value)} />
        <Button icon="pi pi-link" className="p-button-raised p-button-rounded" aria-label="Bookmark" />
        <p>
          crwdid/<span>User</span>
        </p>
        <PrimeAvatar image="/assets/images/tf555.png" className="mr-2" size="normal" shape="circle" />
      </div>
      <div className="main">
        <div className="profile-section">
          <div className="banner-upload">
            <img className="banner-image" src={bannerImage} alt="banner" />
            <FileUpload
              mode="basic"
              name="banner"
              accept="image/*"
              chooseOptions={chooseOptions}
              customUpload
              onSelect={onBannerImageChange}
              auto
            />
          </div>
          <div className="profile-upload">
            <img className="profile-image" src={profileImage} alt="profile" />
            <FileUpload
              mode="basic"
              name="profile"
              accept="image/*"
              chooseOptions={chooseOptions}
              customUpload
              onSelect={onProfileImageChange}
              auto
            />
          </div>
        </div>
        <div className="crwd-score-section justify-content-center w-full">
          <CrwdScores scores={Scores.identityScore} label={Scores.identityScore} />
          <CrwdScores scores={Scores.securityScore} label={Scores.identityScore} />
        </div>

        <Input name="name" control={control as any} label="Name" className="mb-3" />

        <GroupContainer label="Bio">
          <Input name="headline" control={control as any} label="Headline" className="mb-3" />
          <Input name="work" control={control as any} label="Work" className="mb-3" />
          <Input name="location" control={control as any} label="Location" className="mb-3" />
          <Input name="education" control={control as any} label="Education" className="mb-3" />
          <Input name="skills" control={control as any} label="Skills" />
        </GroupContainer>

        <ContainerAccordion
          items={ContactInfoList}
          GroupContainerLabel="Contact Info"
          avatarShape={iconShape}
          avatarSize={iconSize}
          onLinkItemClick={linkItemObj => {
            navigate('/service', { state: linkItemObj });
          }}
        />

        <ContainerAccordion
          items={SocialLinks}
          GroupContainerLabel="Social Links"
          avatarShape={iconShape}
          avatarSize={iconSize}
          onLinkItemClick={linkItemObj => {
            navigate('/service', { state: linkItemObj });
          }}
        />

        <ContainerAccordion
          items={MusicLinks}
          GroupContainerLabel="Music Links"
          avatarShape={iconShape}
          avatarSize={iconSize}
          onLinkItemClick={linkItemObj => {
            navigate('/service', { state: linkItemObj });
          }}
        />

        <ContainerAccordion
          items={PaymentLinks}
          GroupContainerLabel="Payment Links"
          avatarShape={iconShape}
          avatarSize={iconSize}
          onLinkItemClick={linkItemObj => {
            navigate('/service', { state: linkItemObj });
          }}
        />

        <ContainerAccordion
          items={ProductivityLinks}
          GroupContainerLabel="Productivity Links"
          avatarShape={iconShape}
          avatarSize={iconSize}
          onLinkItemClick={linkItemObj => {
            navigate('/service', { state: linkItemObj });
          }}
        />

        <GroupContainer label="Style">
          <div className="box primaryColor">
            <p>Color</p>
            <div className="grid m-0">
              {primaryColors.map((color, i) => (
                <div key={i} className="field-radiobutton">
                  <RadioButton
                    inputId={'pc' + i}
                    name="primaryColor"
                    value={color}
                    onChange={e => setPrimaryColor(e.value)}
                    checked={primaryColor === color}
                  />
                  <label
                    htmlFor={'pc' + i}
                    className={classNames('color-circle', { selected: primaryColor === color })}
                    style={{ background: color }}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="box theme">
            <p>Theme</p>
            <div className="grid m-0">
              {themes.map((tme, i) => (
                <div
                  key={i}
                  className={classNames('field-radiobutton', `${tme}`, { selected: theme === tme })}
                  style={{ background: tme === 'bright' ? '#f1f1f1' : tme === 'dim' ? '#15202b' : '#000' }}
                >
                  <RadioButton
                    inputId={'tme' + i}
                    name="theme"
                    value={tme}
                    onChange={e => setTheme(e.value)}
                    checked={theme === tme}
                  />
                  <label htmlFor={'tme' + i}>{tme}</label>
                </div>
              ))}
            </div>
          </div>

          <div className="box icon">
            <p>Icon Style</p>
            <div className="grid m-0 p-0">
              <div className="grid m-0">
                {iconSizes.map((iconsze, i) => (
                  <div key={i} className={classNames('field-radiobutton', { selected: iconSize === iconsze })}>
                    <RadioButton
                      inputId={'is' + i}
                      name="iconSize"
                      value={iconsze}
                      onChange={e => setIconSize(e.value)}
                      checked={iconSize === iconsze}
                    />
                    <label htmlFor={'is' + i} className={classNames('icon-wrapper')}>
                      <Avatar image={profileImage} size={iconsze} shape={iconShape} />
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </GroupContainer>

        <Button
          label="View Profile"
          icon="pi pi-arrow-right"
          iconPos="right"
          className="btn-shadow mt-4"
          onClick={() => navigate('/:handle')}
        />
      </div>
    </Section>
  );
};

export default SetupPage;
