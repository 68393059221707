import React from 'react';
import { Section, Item } from './index.styled';
import { Container } from 'components/container';
import { LineDivider } from 'components/LineDivider';
import { BGTitleText } from 'components/text';
import { Button } from 'primereact/button';
import data from './data.json';
import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';

const ItemTemplate = ({ img, infoText, infoSubText, text, link, clickHandler }) => (
  <Item onClick={() => clickHandler(link)}>
    <div className="img">
      <img src={img} alt="" />
    </div>
    <div className="deails">
      <div className="info">
        <Button className="p-button-link p-0">{parse(infoText)}</Button>
        <span className="divider">!</span>
        <Button className="p-button-link p-0">{infoSubText}</Button>
      </div>
      <h5 className="text-justify">{text}</h5>
    </div>
  </Item>
);

const UniversalAccess = () => {
  const navigate = useNavigate();

  return (
    <Section>
      <Container>
        <div className="grid">
          <div className="col-12 md:col-4 head">
            <BGTitleText>JOIN US TODAY!</BGTitleText>
            <h3>UNIVERSAL ACCESS FOR ALL</h3>
            <p>
              Our team is passionate about keeping your identity safe online: Claim your id today or click on the links
              on the right to learn more.
            </p>
            <Button label="Take the first step today!" className="p-button-link">
              <LineDivider />
            </Button>
          </div>
          <div className="col-12 md:col-7 md:col-offset-1">
            {data.map((item, i) => (
              <ItemTemplate key={i} {...item} clickHandler={navigate} />
            ))}
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default UniversalAccess;
