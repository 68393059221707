import React from 'react';
import { InputWrapper } from './index.styled';

interface Props {
  id: string;
  label?: string;
  className?: string;
  name?: string;
  register: any;
}

const CheckChips = ({ id, label, name, register, className = '' }: Props) => {
  return (
    <InputWrapper className={className && className}>
      <input type="checkbox" id={id} value={label} {...register(name)} />
      <label htmlFor={id}>{label}</label>
    </InputWrapper>
  );
};

export default CheckChips;
