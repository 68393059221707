import React from 'react';
import { BGTitleText } from 'components/text';
import { CrwdLabel, CrwdScoreSection } from './index.styled';

const CrwdScore = ({ scores }) => {
  return scores.map(score => {
    if (!score.enabled) {
      return '';
    }
    return <BGTitleText>{score.label}</BGTitleText>;
  });
};

const CrwdScores = ({ scores, label }) => {
  return (
    <CrwdScoreSection>
      <CrwdScore scores={scores} />
      <CrwdLabel>
        <span>{label.label}</span>
      </CrwdLabel>
    </CrwdScoreSection>
  );
};

export default CrwdScores;
