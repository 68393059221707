import React, { useCallback, useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { AiOutlineQrcode, AiOutlineDownload } from 'react-icons/ai';
import { BsArrowRightShort } from 'react-icons/bs';
import { ErrorIcon } from 'react-hot-toast';
import { Container, InvalidHandleWrapper } from './index.styled';
import { Spinner } from 'components/spinner';
import { Chip } from 'primereact/chip';
import { default as VCF } from 'vcf';
import IconFromAttributeType from '../icon';
import { useAuthContext } from 'utils/contexts/AuthContext';
import { ContactCardAttribute, ContactCardCodeStatus, useContactCardQuery } from 'graphql/generated/graphql';
import { ValueOrBlank } from 'components/value-or-blank';
import ClaimIdForm from 'components/claim-id-form';
import { Dialog } from 'primereact/dialog';
import { QRCodeSVG } from 'qrcode.react';
import { Toast } from 'primereact/toast';
import { addressSingleLineFormat } from './utils/addressSingleLineFormat';

const crwdIdUrl = process.env.REACT_APP_CRWD_ID_URL;
interface ContactCardDetailsProps {
  handle: string;
  expiringCode?: string;
  qrModeOnly?: boolean;
}

interface Bio {
  name?: string;
  headline?: string;
  work?: string;
  location?: string;
  education?: string;
  skills?: string;
  [key: string]: string;
}

const ContactCardDetails: React.FC<ContactCardDetailsProps> = ({ handle, expiringCode, qrModeOnly }) => {
  const { client } = useAuthContext();
  const contactCardState = useContactCardQuery(client, { handle, expiringCode });
  const contactCard = contactCardState.data?.contactCard;
  const [displayQRModal, setDisplayQRModal] = useState<boolean>(qrModeOnly);
  const toast = useRef<Toast>(null);

  const bio: Bio =
    contactCard?.attributes.reduce((acc, currentAttribute) => {
      const key = currentAttribute.name;
      acc[key] = currentAttribute.value || '';
      return acc;
    }, {}) || {};

  const isContactLink = (attribute: ContactCardAttribute) => {
    return ['email', 'phone', 'address'].includes(attribute.name);
  };

  const links = contactCard?.attributes.filter(attr => isContactLink(attr) || attr.type.group.name.includes('links'));

  function getAttributeValue(attrName: string): string {
    return contactCard?.attributes.find(attr => attr.name === attrName)?.value || '';
  }

  const banner = getAttributeValue('banner');
  const avatar = getAttributeValue('avatar');
  let name = `${getAttributeValue('first_name')} `;
  name += getAttributeValue('middle_name') ? `${getAttributeValue('middle_name')} ` : '';
  name += getAttributeValue('last_name');
  name = name.trim();

  const linkForAttribute = (attribute: ContactCardAttribute): string => {
    if (attribute.type.valueType === 'Link') {
      return attribute.value;
    }
    if (attribute.name === 'email') {
      return `mailto:${attribute.value}`;
    }
    if (attribute.name === 'phone') {
      return `tel:${attribute.value}`;
    }
    if (attribute.name === 'address') {
      const address = JSON.parse(attribute.value)?.address;
      return `https://maps.google.com/?q=${addressSingleLineFormat(address)}`;
    }
    return '#';
  };

  const InvalidHandle = () => {
    return (
      <InvalidHandleWrapper>
        <h3>
          <ErrorIcon />
          Invalid Handle ID
        </h3>
        <ClaimIdForm />
      </InvalidHandleWrapper>
    );
  };

  const generateVCard = useCallback(() => {
    const vCard = new VCF();

    vCard.add('fn', bio.first_name || '');
    vCard.add('n', `${bio.first_name || ''} ${bio.last_name || ''}`);
    vCard.add('title', bio.headline || '');
    vCard.add('role', bio.headline || '');
    vCard.add('org', bio.work || '');
    links?.filter(link => link?.name === 'phone').forEach(link => vCard.add('tel', link?.value));
    links?.filter(link => link?.name === 'email').forEach(link => vCard.add('email', link?.value));
    links
      ?.filter(link => !isContactLink(link))
      .forEach(link => vCard.add('xSocialprofile', link?.value, { type: link?.name }));

    const contactCardAddresses = links
      ?.filter(link => link?.name === 'address')
      .map(link => {
        const completeAddress = JSON.parse(link?.value);
        return { label: completeAddress?.label, address: addressSingleLineFormat(completeAddress?.address) };
      });

    contactCardAddresses?.forEach(address => vCard.add('adr', address.address, { type: address.label }));

    const url = window.URL.createObjectURL(new Blob([vCard.toString()]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${handle}.vcf`);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  }, [bio.first_name, bio.headline, bio.last_name, bio.work, handle, links]);

  const onDisplayQrCodeDialog = () => setDisplayQRModal(true);

  const onHideQrCodeDialog = () => setDisplayQRModal(false);

  const onCopyToClipboard = (url: string): void => {
    navigator.clipboard.writeText(url);
    toast.current?.show({ severity: 'success', summary: 'Link Copied!', life: 3000 });
  };

  if (contactCardState.isLoading) {
    return <Spinner />;
  }

  if (!contactCardState.data || !contactCard || contactCardState.error) {
    return <InvalidHandle />;
  }

  return (
    <>
      <Container>
        {contactCard.codeStatus === ContactCardCodeStatus.Invalid && (
          <p className="invalid-code">* Invalid code. Showing public profile.</p>
        )}

        <Toast ref={toast} />

        <div className="main-information">
          <img className="banner" alt="User banner" src={banner || '/assets/images/online_identity.png'} />
          <img className="avatar" alt="User avatar" src={avatar || '/assets/images/user.png'} />

          <div>
            <div className="header">
              <span className="handle">{handle}</span>
              {name && <span className="name">{name}</span>}
              {bio.headline && <span className="headline">{bio.headline}</span>}
            </div>
            <div className="summary">
              <ValueOrBlank value={bio.work} />
              <ValueOrBlank value={bio.location} />
              <ValueOrBlank value={bio.education} />
            </div>
            {bio.skills && (
              <div className="skills">
                {bio.skills.split(',').map(skill => (
                  <Chip key={skill} label={skill} />
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="buttons">
          <Button icon onClick={onDisplayQrCodeDialog}>
            Share
            <AiOutlineQrcode style={{ marginLeft: '8px' }} />
          </Button>

          <Button icon onClick={generateVCard}>
            <AiOutlineDownload />
          </Button>
        </div>
        <div className="links">
          {links.map(item => (
            <a key={`${item.name}${item.value}`} href={linkForAttribute(item)} target="_blank" rel="noreferrer">
              <>
                <IconFromAttributeType type={item.type} />
                <div className="link-description">
                  <span className="title">{item.type.label}</span>

                  <span>
                    {item.type.name === 'address'
                      ? addressSingleLineFormat(JSON.parse(item.value).address)
                      : item.value}
                  </span>
                </div>
                <Button className="arrow-button" icon>
                  <BsArrowRightShort />
                </Button>
              </>
            </a>
          ))}
        </div>

        <div className="w-full claim-section">
          <ClaimIdForm handle={handle} />
        </div>

        <Dialog
          id="qr1"
          header="QR Code"
          appendTo="self"
          className="qr-dialog"
          visible={displayQRModal}
          onHide={onHideQrCodeDialog}
          breakpoints={{ '960px': qrModeOnly ? '100vw' : '75vw' }}
          style={{ width: '30vw' }}
          footer={false}
          maximized={qrModeOnly}
          blockScroll={true}
          closable={!qrModeOnly}
        >
          <div
            className="flex flex-column align-items-center justify-content-center"
            style={{ cursor: 'pointer', color: 'var(--primary-color)', gap: '20px' }}
          >
            <QRCodeSVG value={`${crwdIdUrl}/${handle}`} />

            <div
              className="flex flex-row align-items-center gap-4"
              onClick={() => onCopyToClipboard(`${crwdIdUrl}/${handle}`)}
            >
              <span className="text-lg">{`${crwdIdUrl}/${handle}`}</span>
              <i className="pi pi-copy" />
            </div>
          </div>
        </Dialog>
      </Container>
    </>
  );
};

export default ContactCardDetails;
