import styled from 'styled-components';

export const BannerWrapper = styled.div`
  width: 100%;
  height: 200px;
  position: relative;

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  button {
    position: absolute;
    top: 10px;
    left: 10px;
  }
`;
