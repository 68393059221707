import React from 'react';
import Banner from 'components/banner';
import { LargeText, SubText } from 'components/text';
import { Section } from './index.styled';
import { Divider } from 'primereact/divider';
import Button from 'components/button';
import { useNavigate } from 'react-router-dom';

const MainPage = () => {
  const navigate = useNavigate();

  return (
    <Section>
      <Banner src="/assets/images/online_identity.png" />
      <div className="m-4">
        <LargeText>Time to Network</LargeText>
        <SubText>upgrade your business card</SubText>
        <Divider />
        <Button
          label="Sign up"
          icon="pi pi-arrow-right"
          iconPos="right"
          className="p-button-outlined p-button-secondary mb-4"
          onClick={() => navigate('/signup')}
        />
        <Button
          label="Log in"
          icon="pi pi-arrow-right"
          iconPos="right"
          className="p-button-secondary btn-shadow"
          onClick={() => navigate('/home')}
        />
      </div>
    </Section>
  );
};

export default MainPage;
