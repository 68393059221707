import React from 'react';
import { Controller, UseControllerProps } from 'react-hook-form';
import { Dropdown as DD } from 'primereact/dropdown';
import { InputWrapper } from './index.styled';

interface Props extends UseControllerProps {
  optionLabel: string;
  options: Array<any>;
  placeholder?: string;
  className?: string;
}

const Dropdown = ({ options, optionLabel, placeholder, className = '', ...rest }: Props) => {
  return (
    <InputWrapper className={className && className}>
      <Controller
        {...rest}
        render={({ field }) => (
          <DD
            id={field.name}
            value={field.value}
            onChange={e => field.onChange(e.value)}
            options={options}
            optionLabel={optionLabel}
            placeholder={placeholder && placeholder}
          />
        )}
      />
    </InputWrapper>
  );
};

export default Dropdown;
