import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin: 40px 0;
  position: relative;
  width: 100%;
  border: 2px solid var(--surface-border);
  padding: 20px 16px;
  border-radius: 15px;
  transition: all 0.3s ease;

  .container-label {
    font-weight: 800;
    font-size: 18px;
    line-height: 128.51%;
    letter-spacing: -0.05em;
    color: var(--primary-color-text);
    position: absolute;
    top: -20px;
    padding: 6px;
    z-index: 1;
    transition: all 0.3s ease;
    border-radius: 10px;
    background: var(--text-color);
  }
`;

const GroupContainer = ({ label, children }) => (
  <Container>
    <label className="container-label">{label}</label>
    {children}
  </Container>
);

export default GroupContainer;
