import React from 'react';
import PreLoading from 'components/pre-loading';
import Banner from './banner';
import ClaimIdForm from 'components/claim-id-form';
import Intro from './intro';
import ProjectDetails from './project-details';
import UniversalAccess from './universal-access';
import VideoWrapper from './video-wrapper';

const ClaimPage = () => {
  return (
    <>
      <PreLoading label="CLAIM YOUR ID" />
      <Banner />
      <Intro
        index={1}
        label="CREATE YOUR ONLINE CONTACT CARD"
        text="<p className='mb-3'>The easiest and most secure way to share your details. A digital business card powered by a blockchain empowers you with digital intelligence.  Not only can you easily share what you want with whom you want instantly, you are positioned to be rewarded for doing so.</p> <p className='mb-3'>Create your online Contact card today!  Be confident knowing that your data is secure on a blockchain.<p/><p className='mb-3'>Grow your network professionally and socially. Share the information you want to share. Never miss an opportunity to grow your network.</p><p className='mb-3'>Your blockchain powered digital contact card is good for business or social occasions.  It is securely and easily shared with anyone you meet.<p/>"
        itemList={[
          'Signup today!',
          'Create a unique QR Code',
          'Create a unique NFT hash to ensure your anonymity',
          'Control how much data and when to share that data about you',
        ]}
      />
      <ProjectDetails />
      <Intro
        index={2}
        label="CLAIM YOUR DATA"
        text="<p className='mb-3'>Once you have created your online contact card, our team will conduct a Big Data and Artificial Intelligence search for your data around the web.</p><p className='mb-3'>Our experts will present you with the results and you determine how you want to secure and share it.</p>"
      />
      <VideoWrapper />
      <UniversalAccess />
      <ClaimIdForm />
    </>
  );
};
export default ClaimPage;
