import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import QueryString from 'query-string';
import ContactCardDetails from '../../components/contact-card-details/card';
import { Section } from './index.styled';
import { useFooterDisplayMode } from 'utils/contexts/useFooterMode';
import { useEffect } from 'react';

const ContactCardPage: React.FC = () => {
  const { handle } = useParams();
  const { setDefaultView } = useFooterDisplayMode();

  useEffect(() => {
    setDefaultView(false);

    return () => {
      setDefaultView(true);
    };
  });

  const location = useLocation();
  const expiringCode = QueryString.parse(location.search)['expiring_code'] as string;
  const qrModeOnly = QueryString.parse(location.search, { parseBooleans: true })['qr_mode_only'] as boolean;

  return (
    <Section>
      <ContactCardDetails handle={handle} expiringCode={expiringCode} qrModeOnly={qrModeOnly} />
    </Section>
  );
};

export default ContactCardPage;
