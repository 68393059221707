
import styled from 'styled-components';

export const InputWrapper = styled.div`
  width: -webkit-fill-available;

  .p-dropdown {
    width: -webkit-fill-available;
  }
`;
