
import styled from 'styled-components';

export const LinkInputCard = styled.div`
  position: relative;
  box-shadow: 0 0 20px rgb(110 135 171 / 15%);
  background: var(--surface-section);
  border-radius: 15px;
  padding: 15px;
  gap: 15px;
  transition: all 0.3s ease;
  width: 100%;

  .p-avatar-xl {
    width: 5.25rem;
    height: 5.25rem;
  }

  h3 {
    padding: 5px 0;
    font-weight: 700;
    font-size: 22px;
    line-height: 27px;
    letter-spacing: -1px;
  }

  button {
    padding: 0.5rem 0.5rem;
    font-weight: 400;
    font-size: 15px;
    width: 100%;

    .p-button-icon-right {
      transform: rotate(30deg);
    }
  }
`;
