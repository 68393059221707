import React from 'react';
import { Item, Section, Progress } from './index.styled';
import { Container } from 'components/container';
import RotateSVG from 'components/RotateSVG';
import data from './data.json';
import { BGTitleText } from 'components/text';
import { ProgressBar } from 'primereact/progressbar';
import VisibilitySensor from 'react-visibility-sensor';
import { CountUp } from 'use-count-up';

const CustomProgressBar = ({ end = 0, label, className = '' }) => {
  return (
    <CountUp isCounting end={end}>
      {({ value }): any => (
        <VisibilitySensor>
          <Progress value={value as number} className={className}>
            <h6>{label}</h6>
            <ProgressBar value={value as number} />
          </Progress>
        </VisibilitySensor>
      )}
    </CountUp>
  );
};

const ItemTemplate = ({ img, rorateCircleText, infoText, infoSubText }) => {
  return (
    <Item>
      <div className="img">
        <img src={img} alt="online contact card" />
        <div className="circle">
          <RotateSVG text={rorateCircleText} />
        </div>
        <div className="info">
          <h6>{infoText}</h6>
          <span>{infoSubText}</span>
        </div>
      </div>
    </Item>
  );
};

const TeamCRV = () => {
  return (
    <Section>
      <Container>
        <div className="grid">
          <div className="col-12 md:col-5 sec-head">
            <BGTitleText>PERFORMANCE | PROTECTION | PRIVACY</BGTitleText>
            <h3>
              In our crwdworld blockchain ecosystem, we are committed to providing you universal access. We are also
              committed to ensuring you enjoy the maximum from your crwdid.
            </h3>
            <CustomProgressBar end={100} label="YOUR ID AS A CONSUMER" />
            <CustomProgressBar end={100} label="YOUR ID AS AN INVESTOR" />
            <CustomProgressBar end={100} label="YOUR ID AS A COMPANY MEMBER" className="m-0" />
          </div>
          <div className="col-12 md:col-6 md:col-offset-1 ">
            <div className="grid m-0">
              {data.map((item, i) => (
                <div key={i} className="col-12 md:col-6 p-0 mb-5">
                  <ItemTemplate {...item} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </Section>
  );
};

export default TeamCRV;
