
import { ParallaxBanner as P } from 'react-scroll-parallax';
import styled from 'styled-components';

export const ParallaxBanner = styled(P)`
  div:first-child {
    background-size: contain !important;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: #080718;
  }

  .container {
    z-index: 1;
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    > * {
      z-index: 1;
    }

    button {
      min-width: auto;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 50%;
      text-align: center;
      font-size: 17px;
      position: relative;
      transition: all 0.5s;
      padding: 40px;
      background: transparent;

      &:before {
        content: '';
        position: absolute;
        top: 5px;
        bottom: 5px;
        right: 5px;
        left: 5px;
        background: #fff;
        border-radius: 50%;
        z-index: -1;
        transition: all 0.5s cubic-bezier(1, 0, 0, 1);
      }

      &:after {
        content: '';
        position: absolute;
        top: 5px;
        bottom: 5px;
        right: 5px;
        left: 5px;
        border: 1px solid #eee;
        border-radius: 50%;
        z-index: -1;
      }

      &:hover {
        background: transparent;
        color: #fff;

        &:before {
          transform: scale(0);
        }
      }

      &:focus {
        background: transparent;
      }
    }
  }

  @media (max-width: 576px) {
    .container {
      min-height: 300px;
    }
  }
`;
