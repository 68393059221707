import parse from 'html-react-parser';
import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0);
  }
`;

const SVG = styled.svg`
  width: 225px;
  animation-name: ${rotate};
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

const RotateSVG = ({ text = '' }) => (
  <SVG
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="300px"
    height="300px"
    viewBox="0 0 300 300"
    enable-background="new 0 0 300 300"
  >
    <defs>
      <path id="circlePath" d=" M 150, 150 m -60, 0 a 60,60 0 0,1 120,0 a 60,60 0 0,1 -120,0 "></path>
    </defs>
    <circle cx="150" cy="100" r="75" fill="none"></circle>
    <g>
      <use href="#circlePath" fill="none"></use>
      <text fill="#fff">
        <textPath href="#circlePath">{parse(text)}</textPath>
      </text>
    </g>
  </SVG>
);

export default RotateSVG;
