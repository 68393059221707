
import styled from 'styled-components';

export const Containter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 45px;
  z-index: 40;
  border-radius: 50%;
  position: relative;
  > svg {
    &.scale-75 {
      transform: scale(0.75);
    }
  }
`;
