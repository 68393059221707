import React from 'react';
import { Item, Section } from './index.styled';
import { Container } from 'components/container';
import RotateSVG from 'components/RotateSVG';

import { GradiantText } from 'components/text';

const ItemTemplate = ({ img, rorateCircleText, infoText, infoSubText }) => {
  return (
    <Item>
      <div className="img">
        <img src={img} alt="online contact card" />
        <div className="circle">
          <RotateSVG text={rorateCircleText} />
        </div>
        <div className="info">
          <h6>{infoText}</h6>
          <span>{infoSubText}</span>
        </div>
      </div>
    </Item>
  );
};

const Benefits = props => {
  const cssClass = 12 / props.data.length;
  return (
    <Section>
      <Container fluid>
        {props.text && (
          <div className="grid">
            <div className="col-12 md:col-8 md:col-offset-2 sec-head">
              <h6>4 BENEFITS IN ONE</h6>
              <GradiantText>The contact card for online</GradiantText>
            </div>
          </div>
        )}
        <div className="grid m-0">
          {props.data.map((item, i) => (
            <div key={i} className={`col-12 md:col-${cssClass}`}>
              <ItemTemplate {...item} />
            </div>
          ))}
        </div>
      </Container>
    </Section>
  );
};

export default Benefits;
