
import { ParallaxBanner as P } from 'react-scroll-parallax';
import styled from 'styled-components';

export const ParallaxBanner = styled(P)`
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    min-height: 70vh;
    position: relative;

    > * {
      z-index: 1;
    }

    .grid {
      width: 100%;

      h5 {
        color: var(--primary-color);
        font-size: 13px;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 4px;
        margin-bottom: 10px;
      }

      .heading {
        font-weight: 700;
        font-size: 30px;
        line-height: 1.2;
        text-transform: uppercase;
        margin-bottom: 20px;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: #fff;
        color: transparent;

        h6 {
          font-weight: 900;
          font-size: 30px;
          border-radius: 0;
          color: #fff;
          letter-spacing: 2px;
        }
      }
    }
  }

  @media (max-width: 576px) {
    .container {
      min-height: 60vh;

      .grid {
        gap: 30px;

        .heading {
          font-size: 20px !important;

          h6 {
            font-size: 20px !important;
          }
        }
      }
    }
  }
`;
