import React from 'react';
import { Dialog as D } from 'primereact/dialog';
import ReactPlayerLoader from '@brightcove/react-player-loader';
import styled from 'styled-components';

const Dialog = styled(D)`
  padding: 0 !important;
  box-shadow: none !important;

  .p-dialog-header {
    display: none;
  }

  .p-dialog-content {
    padding: 0px;

    .player-wrapper {
      width: 100vw;
      max-width: 880px;
      position: relative;
      padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
    }

    video-js {
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
    }
  }
`;

interface PropsType {
  show: boolean;
  onHide: () => void;
  className?: string;
  videoUrl: string;
}

const PlayerDialog = ({ className = '', show, onHide, videoUrl }: PropsType) => {
  return (
    <Dialog visible={show} onHide={onHide} className={className} dismissableMask>
      <div className="player-wrapper">
        <ReactPlayerLoader
          accountId="6264577224001"
          videoId={videoUrl}
          options={{ autoplay: 'muted', controls: false }}
        />
      </div>
    </Dialog>
  );
};

export default PlayerDialog;
