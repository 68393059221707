import styled from 'styled-components';

export const Section = styled.section`
  padding: 120px 0;
  background: var(--surface-section);

  .head {
    h6 {
      display: inline-block;
      padding: 7px 12px;
      font-size: 15px;
      border-radius: 10px;
      margin-bottom: 15px;
      font-weight: 500;
      letter-spacing: 0;
    }

    h3 {
      line-height: 1.4;
      font-size: 35px;
      font-weight: 600;
      margin-bottom: 5px;
    }

    p {
      font-size: 16px;
      line-height: 2;
      margin: 0;
    }

    button {
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 10px;
      margin-top: 40px;
      padding-bottom: 10px;
      position: relative;
      padding-left: 0;
      text-align: left;

      .p-button-label {
        text-decoration: none !important;
      }

      .line-divider {
        width: 40%;
        transition: all 0.4s;
      }

      &:hover {
        .line-divider {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 576px) {
    .grid {
      gap: 20px;
    }
  }
`;

export const Item = styled.div`
  display: flex;
  padding-bottom: 30px;
  margin-bottom: 50px;
  cursor: pointer;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .img {
    width: 350px;
    img {
      width: 100%;
      height: auto;
    }
  }

  .deails {
    padding-left: 30px;
    .info {
      margin-bottom: 15px;

      button {
        margin-left: 15px;
        ext-transform: uppercase;
        letter-spacing: 3px;
        font-size: 10px;
        display: inline-block;
        color: var(--primary-color);
      }

      .divider {
        font-size: 10px;
        margin-left: 5px;
        vertical-align: bottom;
      }
    }

    h5 {
      font-size: 22px;
      font-weight: 500;
    }
  }

  @media (max-width: 576px) {
    .deails {
      h5 {
        font-size: 16px !important;
      }
    }
  }
`;
