import React from 'react';
import { Breadcrumbs } from 'components/breadcrumbs';
import ClaimIdForm from 'components/claim-id-form/ClaimIdForm';
import PreLoading from 'components/pre-loading';
import Benefits from 'components/benefits';
import { Section } from './index.styled';
import data from './data.json';

const Points = [
  {
    text: "When you create a crwdid contact card, you create a one-time secret hash. You determine how long that hash will be available for someone to view.  Every time you want to share your contact information, it's a completely new secure share with a time limit.",
  },
  {
    text: 'A key aspect of privacy on the Vogon Blockchain on our decentralized cloud is the use of private and public keys. Blockchain systems use asymmetric cryptography to secure transactions between users.',
  },
  {
    text: 'In Vogon, each user has a public and private key. These keys are random strings of numbers and are cryptographically related.',
  },
  {
    text: 'Our Vogon Blockchain is a permissioned blockchain that is a distributed ledger not publicly accessible. It can only be accessed by users with permissions.',
  },
  {
    text: 'In order to join crwdid users have to provide different layers of proof on their identity.  They must also undergo multiple layers of security protocols.',
  },
];

const ProtectionPage = () => {
  return (
    <Section>
      <PreLoading label="PROTECTION" />
      <Breadcrumbs
        className="breadcrumbs"
        label="PROTECTION"
        text="Claim your Online Identity and begin to reap rewards."
      />
      <div className="grid">
        <div className="col-12 md:col-10 md:col-offset-1">
          <img src="/assets/images/Protection_Wall.png" width="100%" alt="protection wall" />
          <div className="grid">
            <div className="col-10 col-offset-1">
              <h4>How will your identity be protected online?</h4>
              <h6 className="my-4">
                Online security is important to keeping hackers and cyber thieves from accessing your sensitive
                information. Without a proactive identity protection strategy, you risk the spread and escalation of
                malware, and attacks on home networks, computers, and other IT infrastructure.
              </h6>
              <p className="my-5">
                Our crwdid is better than a paper business card - if you lose a business card thieves have enough data
                on that card to steal from you. A crwdid ensures you are protected.{' '}
              </p>
              <h6>ENJOY PERSONAL IDENTITY PROTECTION IN AND OUT OF THE BLOCKCHAIN ECOSYSTEM!</h6>
              <p className="my-5">
                Enjoy protection in crwdmarket, on your wallet on crwdcapital, and investing in crwdfinance. You choose
                how long and when to share your data.
              </p>

              <ul>
                {Points.map((point, i) => (
                  <li key={i}>
                    <span>0{i + 1}</span> {point.text}
                  </li>
                ))}
              </ul>

              <div className="quotes text-center">
                <p>CLAIM YOUR ONLINE CONTACT CARD TODAY!</p>
              </div>
              <Benefits data={data} />
              <ClaimIdForm />
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};
export default ProtectionPage;
