import React from 'react';
import Button from 'components/button';
import { Container } from 'components/container';
import { ParallaxBanner } from './index.styled';

const Banner = () => {
  return (
    <ParallaxBanner
      layers={[
        {
          image: '/assets/images/3d_background.png',
          speed: 30,
        },
      ]}
    >
      <Container className="container">
        <div className="grid">
          <div className="md:col-9">
            <div className="header-wrap">
              <h5>CLAIM</h5>
              <h2>YOUR IDENTITY TODAY</h2>
            </div>
          </div>
        </div>
        <div className="grid">
          <div className="md:col-3">
            <div className="item">
              <h6>ONLINE CONTACT CARD</h6>
              <Button label="SECURELY SHARE YOUR CONTACT INFORMATION" className="p-button-link" />
            </div>
          </div>
          <div className="md:col-3">
            <div className="item">
              <h6>PERFORMANCE</h6>
              <Button label="ENJOY NEW KINDS OF DIGITAL REBATES" className="p-button-link" />
            </div>
          </div>
          <div className="md:col-3">
            <div className="item">
              <h6>PROTECTION</h6>
              <Button label="OUR VOGON BLOCKCHAIN" className="p-button-link" />
              <Button label="KEEPS YOUR PII SECURE" className="p-button-link" />
            </div>
          </div>
          <div className="md:col-3">
            <div className="item">
              <h6>PRIVACY</h6>
              <Button label="ANONYMOUSLY" className="p-button-link" />
              <Button label="BROWSE ONLINE" className="p-button-link" />
            </div>
          </div>
        </div>
      </Container>
    </ParallaxBanner>
  );
};

export default Banner;
