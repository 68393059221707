
import { ParallaxBanner as P } from 'react-scroll-parallax';
import styled from 'styled-components';

export const Section = styled.section`
  position: relative;
  overflow: hidden;

  .testim-box {
    position: absolute;
    bottom: 100px;
    right: 5%;
    padding: 80px 40px;
    z-index: 2;
    width: 470px;

    &:after {
      content: '';
      position: absolute;
      left: -10px;
      top: -10px;
      right: -10px;
      bottom: -10px;
      background: linear-gradient(to right, #12c2e9, #c471ed, #f64f59);
      z-index: -4;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0px;
      top: 0px;
      right: 0px;
      bottom: 0px;
      background: #111215;
      z-index: -1;
    }

    .head-box {
      h6 {
        color: var(--primary-color);
        font-size: 12px;
        font-weight: 300;
        text-transform: uppercase;
        letter-spacing: 4px;
        margin-bottom: 10px;
      }

      h4 {
        font-size: 30px;
        line-height: 1.4;
        font-weight: 500;
        margin-bottom: 40px;
      }
    }

    .slick-dots {
      text-align: right;

      li {
        width: 7px;
        height: 7px;
        border-radius: 50%;
        border: 1px solid var(--primary-color);

        &.slick-active {
          background: var(--primary-color);
        }

        button {
          display: none;
        }
      }
    }
  }

  .skill-wrapper {
    padding: 50px 0 34px;
    background: var(--surface-section);
  }

  @media (max-width: 576px) {
    .testim-box {
      position: relative;
      right: -10px;
      padding: 50px 20px;
      width: calc(100% - 20px);
      top: -400px;

      .head-box {
        h4 {
          font-size: 20px;
        }
      }
    }

    .skill-wrapper {
      margin-top: -400px;
      padding: 50px 0 34px;
      background: #212329;
    }
  }
`;

export const ParallaxBanner = styled(P)`
  > div:nth-child(2) {
    z-index: 1;
    min-height: 700px;
    position: relative;

    > * {
      z-index: 1;
    }

    .vid-area {
      position: absolute;
      top: 0px;
      left: 5%;
      padding: 30px 15px;
      background: #ffff;
      text-align: center;
      z-index: 2;

      &:after {
        content: '';
        position: absolute;
        top: 0px;
        left: -10px;
        right: -10px;
        bottom: -10px;
        background: #fff;
        opacity: 0.2;
        z-index: -1;
      }

      .text {
        display: block;
        color: #000;
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        transform: rotate(-90deg);
        margin-top: 30px;
      }

      button {
        margin-top: 50px;
      }
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #080718;
    opacity: 0.5;
  }
`;

export const Item = styled.div`
  p {
    font-weight: 300;
    line-height: 2;
    margin: 0;
    font-size: 17px;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  .info {
    .img-box {
      display: table-cell;
      vertical-align: middle;

      .p-avatar {
        width: 70px;
        height: 70px;
      }
    }

    .author {
      display: table-cell;
      vertical-align: middle;
      padding-left: 15px;

      .author-name {
        font-size: 16px;
        margin-bottom: 5px;
        letter-spacing: 2px;
        text-transform: uppercase;
      }

      .author-details {
        font-size: 13px;
        font-weight: 300;
        text-transform: uppercase;
      }
    }
  }
`;

export const SkillItem = styled.div`
  display: flex;
  align-items: center;

  .p-knob {
    display: flex;

    svg {
      text {
        fill: var(--text-color);
      }
    }
  }

  .cont {
    padding-left: 25px;

    span {
      display: block;
      font-size: 12px;
      font-weight: 300;
      opacity: 0.9;
      margin-bottom: 10px;
    }

    h6 {
      font-size: 15px;
      font-weight: 500;
    }
  }
`;
