import React from 'react';
import { AvatarShapeType, AvatarSizeType } from 'primereact/avatar';
import { classNames } from 'primereact/utils';
import { useState } from 'react';
import styled from 'styled-components';
import GroupContainer from './group-container';
import SmallLinkCard from './SmallLinkCard';

const Card = styled.div`
  .stack {
    display: flex;
    justify-content: center;
    grid-gap: 5px;
    flex-wrap: wrap;
  }
  i {
    transition: all 0.25s ease;
    transition-delay: 0.15s;
  }

  .active {
    i {
      color: var(--danger-color);
      transform: rotate(45deg);
    }
  }
`;

interface Props {
  items: Array<any>;
  GroupContainerLabel: string;
  avatarShape?: AvatarShapeType;
  avatarSize?: AvatarSizeType;
  onLinkItemClick?: ({ title, icon }) => void;
}

const ContainerAccordion = ({
  GroupContainerLabel,
  items,
  avatarShape = 'circle',
  avatarSize = 'large',
  onLinkItemClick,
}: Props) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Card>
      <GroupContainer label={GroupContainerLabel}>
        <div className="stack">
          {items.slice(0, isOpen ? items.length : 3).map((link, i) => (
            <SmallLinkCard
              key={i}
              {...link}
              avatarShape={avatarShape}
              avatarSize={avatarSize}
              onClick={() => onLinkItemClick && onLinkItemClick(link)}
            />
          ))}

          {items.length > 3 && (
            <SmallLinkCard
              className={classNames({ active: isOpen })}
              icon="/assets/images/tf555.png"
              title="Link"
              basic
              onClick={() => setIsOpen(!isOpen)}
            />
          )}
        </div>
      </GroupContainer>
    </Card>
  );
};

export default ContainerAccordion;
