import styled from 'styled-components';

export const Section = styled.section`
  background: var(--surface-100);
  min-height: 100vh;

  .header-wrapper {
    margin: 0;
    padding: 1.2rem 1.2rem;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0px;
  }

  .main {
  }

  @media (max-width: 576px) {
  }
`;
