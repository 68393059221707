
import { ParallaxBanner as P } from 'react-scroll-parallax';
import styled from 'styled-components';

export const ParallaxBanner = styled(P)`
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    min-height: 70vh;
    position: relative;

    > * {
      z-index: 1;
    }

    button {
      padding: 4rem;

      .p-button-icon {
        font-size: 30px;
      }
    }

    .stauts {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;

      .item {
        padding: 30px 40px;
        min-width: 200px;
        background: #272727;
        margin-right: 30px;

        &:last-of-type {
          background: #fff;
          color: #121a21;
        }

        h4 {
          font-size: 30px;
          margin: 0;
          line-height: 1.4;
          font-weight: 500;

          span {
            font-size: 20px;
            margin-left: 2px;
          }
        }

        h6 {
          font-size: 15px;
          margin-top: 5px;
          margin: 0;
          line-height: 1.4;
          font-weight: 500;
        }
      }
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.2) 0%, rgba(43, 47, 53, 0.7) 100%);
    background-repeat: repeat-x;
  }

  @media (max-width: 576px) {
    .container {
      min-height: 50vh;

      button {
        padding: 2rem;

        .p-button-icon {
          font-size: 15px;
        }
      }

      .stauts {
        .item {
          padding: 10px 20px;
          min-width: 100px;
          margin-right: 30px;

          h4 {
            font-size: 20px;
            line-height: 1.4;

            span {
              font-size: 10px;
              margin-left: 2px;
            }
          }

          h6 {
            font-size: 12px;
            margin-top: 5px;
            line-height: 1.2;
          }
        }
      }
    }
  }
`;
