import React from 'react';
import { ParallaxBanner, Section } from './index.styled';

const UniversalAccess = () => {
  return (
    <Section>
      <ParallaxBanner
        layers={[
          {
            image: '/assets/images/6.jpg',
            speed: 0,
          },
        ]}
      >
        <div className="caption copy-cap">
          <h1>UNVIVERSAL ACCESS</h1>
        </div>
        <div className="caption">
          <h1 className="stroke">UNVIVERSAL ACCESS</h1>
        </div>
      </ParallaxBanner>
    </Section>
  );
};

export default UniversalAccess;
