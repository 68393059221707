import styled from 'styled-components';

export const Section = styled.section`
  background: var(--surface-section);
  padding-bottom: 20px;
  min-height: 100vh;

  img {
    width: 100%;
    height: 200px;
  }

  .username-input {
    input {
      padding-left: 110px;
      font-weight: 700;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: -1px;
    }

    .username-prefix {
      position: absolute;
      top: calc(50% - 11px);
      left: 15px;
      margin: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      letter-spacing: -0.055em;
    }
  }
  .grid {
    margin: 0;
    margin-top: 0.5rem !important;
    gap: 7px;
  }

  .p-button-link {
    color: var(--text-color);
    font-weight: 400;
    font-size: 16px;
    text-decoration: underline;

    &:hover {
      color: var(--text-color) !important;
      opacity: 0.9;
    }
  }

  @media (max-width: 576px) {
  }
`;
