
import styled from 'styled-components';

export const Section = styled.section`
  .grid {
    padding: 120px 0 0;
  }

  h4 {
    line-height: 1.4;
    font-weight: 500;
    font-size: 30px;
  }

  p {
    color: var(--text-color);
    font-size: 16px;
    font-weight: 300;
    line-height: 2;
    margin: 0;
    margin-bottom: 10px;
  }

  @media (max-width: 576px) {
  }
`;
