import React from 'react';
import { Item, Section, Slide } from './index.styled';
import { Settings } from 'react-slick';
import { GradiantText } from 'components/text';
import testimonals from './testimonals.json';

const ItemTemplate = ({ authorName, authorDetails, authorImage, message }) => {
  return (
    <Item>
      <div className="author">
        <div className="img">
          <img src={authorImage} alt="" />
        </div>
        <GradiantText className="author-name">{authorName}</GradiantText>
        <span className="author-details">{authorDetails}</span>
      </div>
      <p>{message}</p>
    </Item>
  );
};

const Testimonials = () => {
  var settings = {
    arrows: true,
    dots: false,
    infinite: true,
    centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding: '28%',
    autoplay: true,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          centerMode: false,
        },
      },
    ],
  } as Settings;

  return (
    <Section className="testimonials">
      <Slide {...settings}>
        {testimonals.map((testimonal, i) => (
          <ItemTemplate
            key={i}
            authorName={testimonal.authorName}
            authorDetails={testimonal.authorDetails}
            authorImage={testimonal.authorImage}
            message={testimonal.message}
          />
        ))}
      </Slide>
    </Section>
  );
};

export default Testimonials;
