import { Address } from 'graphql/generated/graphql';

export function addressSingleLineFormat(address?: Address): string {
  let addressString = address?.street1;
  if (address?.street2) {
    addressString += `, ${address?.street2}`;
  }
  return `${addressString ?? ''}, ${address?.city ?? ''}, ${address?.stateCode?.toLocaleUpperCase() ?? ''} ${
    address?.postalCode ?? ''
  }`;
}
