import React from 'react';
import { LargeText } from 'components/text';
import { Section } from './index.styled';
import Button from 'components/button';
import { useLocation, useNavigate } from 'react-router-dom';
import LinkInputForm from 'components/link-input-form';

export interface LinkProps {
  title: string;
  icon: string;
}

const ServicePage = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const item = location.state as LinkProps;

  return (
    <Section>
      <div className="header-wrapper grid">
        <Button
          icon="pi pi-arrow-left"
          className="p-button-rounded p-button-secondary p-button-outlined"
          onClick={() => navigate(-1)}
        />
        <LargeText>Edit {item.title}</LargeText>
      </div>
      <div className="main">
        <LinkInputForm item={item} />
      </div>
    </Section>
  );
};

export default ServicePage;
