import React from 'react';
import { GlobalStyle } from 'index.styled';
import ReactDOM from 'react-dom';
import { ParallaxProvider } from 'react-scroll-parallax';
import { ThemeProvider } from 'theme';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <>
    <ThemeProvider>
      <ParallaxProvider>
        <GlobalStyle />
        <App />
      </ParallaxProvider>
    </ThemeProvider>
  </>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
