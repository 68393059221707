
import styled from 'styled-components';

export const InputWrapper = styled.div`
  display: inline-block;

  input {
    display: none;

    &:checked {
      ~ label {
        border: 1.5px solid var(--surface-900) !important;

        &:after {
          display: inline-block;
        }
      }
    }
  }

  label {
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    background: var(--surface-200);
    padding: 6px 10px;
    border-radius: 72px;
    cursor: pointer;
    border: 1.5px solid var(--surface-200) !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    transition: all 0.3s ease;
    margin: 0;

    &:after {
      display: none;
      content: '\\e90a';
      font-family: 'primeicons';
      color: var(--success-color);
      transition: all 0.3s ease;
    }
  }
`;
