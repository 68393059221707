import React, { createContext, PropsWithChildren, useContext, useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import { GraphQLClient } from 'graphql-request';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useSearchParams } from 'react-router-dom';

const graphqlUrl = process.env.REACT_APP_HASURA_GRAPHQL_ENDPOINT + '/v1/graphql';
const queryClient = new QueryClient();

export interface AuthContextType {
  affiliateCode?: string,
  token: string;
  userId: string;
  userRole: string;
  client: GraphQLClient;
  logout: () => void;
}

export const AuthContext = createContext<AuthContextType>({
  token: '',
  userId: '',
  userRole: '',
  client: clientWithToken(),
  logout: () => {},
});

export const useAuthContext = (): AuthContextType => useContext(AuthContext);
export const useClient = (): GraphQLClient => useAuthContext().client;

export function clientWithToken(token?: string): GraphQLClient {
  const headers = token ? { Authorization: `Bearer ${token}` } : undefined;
  return new GraphQLClient(graphqlUrl, { headers });
}

function userIdFromToken(token?: string): string | undefined {
  if (!token) {
    return undefined;
  }
  const payload = jwtDecode<Record<string, any>>(token);
  return payload['https://hasura.io/jwt/claims']['x-hasura-user-id'];
}

function userRoleFromToken(token?: string): string | undefined {
  if (!token) {
    return undefined;
  }
  const payload = jwtDecode<Record<string, any>>(token);
  return payload['https://hasura.io/jwt/claims']['x-hasura-default-role'];
}

export const AuthProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const [affiliateCode, setAffiliateCode] = useState('');
  const [token] = useState('');

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!affiliateCode) {
      setAffiliateCode(searchParams.get('afmc') || undefined)
    }
  }, [affiliateCode, searchParams])

  const handleLogout = () => {
    // TODO:
  }

  const authContextValue: AuthContextType = {
    affiliateCode,
    token,
    userId: userIdFromToken(token),
    userRole: userRoleFromToken(token),
    client: clientWithToken(token),
    logout: handleLogout,
  };

  return (
    <AuthContext.Provider value={authContextValue}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </AuthContext.Provider>
  );
};
