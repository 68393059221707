import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 300px;

  .invalid-code {
    color: #f64f59;
  }

  .main-information {
    width: 100%;
    border-radius: 15px;
    position: relative;
    background-color: #0c0931;

    > div {
      padding: 0 12px 12px 12px;
    }

    .banner {
      width: 100%;
      border-radius: 15px;
      height: 140px;
      object-fit: cover;
    }

    .avatar {
      border-radius: 50%;
      height: 120px;
      border: 1px solid #18191d;
      position: absolute;
      top: 30px;
      left: 20px;
    }

    .header {
      display: flex;
      flex-direction: column;
    }

    .handle {
      font-weight: bold;
      font-size: 24px;
      color: #fff;
      line-height: 1;
      margin: 5px 0 5px 0;
    }

    .name {
      font-size: 16px;
      line-height: 1.2;
    }

    .headline {
      font-size: 16px;
      line-height: 1.2;
    }

    .summary {
      display: flex;
      flex-direction: column;
      font-size: 13px;
      color: #c3cad1;
      line-height: 1.2;
      position: relative;
      margin-left: 7px;
      margin-top: 5px;

      &::before {
        content: '';
        position: absolute;
        width: 2px;
        height: 80%;
        top: 10%;
        left: -7px;
        background-color: #b9cad3;
        border-radius: 3px;
      }
    }

    .skills {
      display: flex;
      margin-top: 12px;

      > div {
        background-color: rgba(184, 191, 202, 0.3);
        color: #fff;
        margin-right: 3px;
        border-radius: 72px;
        font-size: 11px;
        font-family: 'Open Sans', Arial;
      }
    }
  }

  .buttons {
    display: flex;
    width: 100%;
    margin-top: 16px;
    padding: 0 8px;

    .p-button {
      display: flex;
      justify-content: center;
      line-height: 1;
      height: 40px;
      background-color: #18191d;
      color: #fff;
      transition: all 0.3s;

      &:hover {
        transform: scale(0.95);
      }

      &:first-of-type {
        flex: 1;
        margin-right: 8px;
      }
    }
  }

  .links {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    margin-top: 16px;

    > a {
      display: flex;
      flex-direction: row;
      height: 60px;
      width: 100%;
      position: relative;
      justify-content: flex-start;
      align-items: center;
      padding: 0px 16px;
      gap: 16px;
      background-color: #0c0931;
      border-radius: 8px;
      position: relative;
      text-decoration: none;

      .link-icon {
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 50px;
        width: 50px;
        z-index: 1;
        position: absolute;
        background-color: #18191d;
      }

      .link-description {
        flex: 1;
        display: flex;
        flex-direction: column;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        color: #fff;

        .title {
          font-size: 14px;
          font-weight: bold;
        }

        span {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .arrow-button {
        background-color: transparent;
        color: #fff;
        width: 30px;
        padding: 0;
        > svg {
          height: 28px;
          width: 32px;
        }
      }
    }
  }

  .claim-section {
    margin-top: 40px;
  }

  #qr1_content{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: 576px) {
    width: 450px;
  }
`;

export const InvalidHandleWrapper = styled.div`
  width: 100%;
  text-align: center;
  h3 {
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    margin-bottom: 40px;
  }
`;
