import React from 'react';
import { AvatarShapeType, AvatarSizeType } from 'primereact/avatar';
import styled from 'styled-components';
import Avatar from './Avatar';

const Card = styled.div`
  width: 80px;
  height: 82px;
  display: flex;
  flex-direction: column;
  grid-gap: 6.5px;
  gap: 6.5px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  z-index: 2;
  position: relative;
  cursor: pointer;

  .title {
    font-weight: 600;
    font-size: 11px;
    line-height: 17px;
    transition: all 0.3s ease;
    transition: var(--transition-ease);
  }

  .indicator {
    position: absolute;
    height: 10px;
    width: 10px;
    background: #6bfa8b;
    border-radius: 50%;
    right: 4px;
    top: 4px;
    box-shadow: 0 0 5px #6bfa8b;
    z-index: 8;
  }

  &:active {
    box-shadow: 0 0 20px rgb(110 135 171 / 30%);
  }
`;

interface Props {
  className?: string;
  icon?: string;
  title?: string;
  basic?: boolean;
  indicator?: boolean;
  avatarShape?: AvatarShapeType;
  avatarSize?: AvatarSizeType;
  onClick?: () => void;
}

const SmallLinkCard = ({
  className = '',
  icon = '/assets/images/tf555.png',
  title = 'Link',
  basic,
  indicator,
  avatarShape = 'circle',
  avatarSize = 'large',
  onClick,
}: Props) => {
  return (
    <Card className={className} onClick={onClick && onClick}>
      {!basic && (
        <>
          <Avatar image={icon} size={avatarSize} shape={avatarShape} />
          <p className="title m-0">{title}</p>
          {indicator && <div className="indicator" />}
        </>
      )}
      {basic && <i className="pi pi-plus" />}
    </Card>
  );
};

export default SmallLinkCard;
