import React from 'react';
import { Container } from 'components/container';
import SocialLinks from 'components/social';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { DefaultFooterWrapper, ClaimFooterWrapper } from './index.styled';
import { useFooterDisplayMode } from 'utils/contexts/useFooterMode';

const DefaultFooter = () => (
  <DefaultFooterWrapper>
    <Container>
      <div className="grid">
        <div className="col-12 md:col-4 contact">
          <h2>Contact us</h2>

          <ul>
            <li>
              <i className="pi pi-map-marker" />
              <div className="cont">
                <h6>Austin Office</h6>
                <p>510815 Ranch Rd 2222, Austin, TX 78730, USA</p>
              </div>
            </li>
            <li>
              <i className="pi pi-envelope" />
              <div className="cont">
                <h6>Email Us</h6>
                <p>support@crwd.id</p>
              </div>
            </li>
            <li>
              <i className="pi pi-phone" />
              <div className="cont">
                <h6>Call Us</h6>
                <p>+1 512 767 1773</p>
              </div>
            </li>
          </ul>
        </div>
        <div className="col-12 md:col-4 explore">
          <h2>Explore our crwdworld</h2>

          <ul>
            <li>
              <div className="img">
                <img src="/assets/images/crwdmarket_crwdid.png" alt="crwd market" />
              </div>
              <div className="post">
                <p>crwdmarket</p>
                <a rel="noreferrer" target="_blank" href="https://crwd.world/about">
                  Only on crwdworld
                </a>
              </div>
            </li>
            <li>
              <div className="img">
                <img src="/assets/images/crwdcapital_crwdid.png" alt="crwd capital" />
              </div>
              <div className="post">
                <p>crwdcapital</p>
                <a rel="noreferrer" target="_blank" href="https://crwd.world/about">
                  Only on crwdworld
                </a>
              </div>
            </li>
            <li>
              <div className="img">
                <img src="/assets/images/crwdfinance_crwdid.png" alt="crwd finance" />
              </div>
              <div className="post">
                <p>crwdfinance</p>
                <a rel="noreferrer" target="_blank" href="https://crwd.world/about">
                  Only on crwdworld
                </a>
              </div>
            </li>
            <li>
              <div className="subscribe">
                <InputText name="email" placeholder="Type Your Email" />
                <Button icon="pi pi-send" className="p-button-rounded" />
              </div>
            </li>
          </ul>
        </div>
        <div className="col-12 md:col-4 copy-right">
          <img src="/assets/images/logo-light.png" alt="logo" />
          <SocialLinks
            links={{
              fb: '#',
              twitter: '#',
              instagram: '#',
              youtube: '#',
            }}
          />
          <p>
            © 2022, CRWDID: YOUR PASSPORT TO:
            <Button label="CRWDWORLD" className="p-button-link p-0" />.
          </p>
        </div>
      </div>
    </Container>
  </DefaultFooterWrapper>
);

const ClaimFooter = () => (
  <ClaimFooterWrapper>
    <Container>
      <a href="https://crwd.world/">
        <img src="/assets/images/crwdworld.png" alt="crwd world" />
      </a>
    </Container>
  </ClaimFooterWrapper>
);

const Footer = () => {
  const { defaultView } = useFooterDisplayMode();

  return defaultView ? <DefaultFooter /> : <ClaimFooter />;
};

export default Footer;
