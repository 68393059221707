import styled from 'styled-components';

export const Section = styled.section`
  padding: 100px 0;
  overflow: hidden;

  .sec-head {
    position: relative;
    margin: 0 auto 80px;

    h6 {
      font-weight: 300;
      font-size: 17px;
      text-transform: uppercase;
      letter-spacing: 7px;
      margin-bottom: 10px;
      text-align: center;
    }

    h3 {
      text-align: center;
      font-size: 48px;
      font-weight: 500;
    }
  }

  @media (max-width: 576px) {
    h6 {
      font-size: 15px !important;
      letter-spacing: 5px !important;
    }

    h3 {
      font-size: 30px !important;
      line-height: 32px !important;
    }
  }
`;

export const Item = styled.div`
  padding: 0 35px;

  .img {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0px;
      left: 0;
      right: 0;
      bottom: 0px;
      background: #000;
      opacity: 0;
      transition: all 0.4s;
    }

    img {
      width: 100%;
      height: auto;
    }

    .circle {
      position: absolute;
      top: -30%;
      right: -50%;
      width: 100%;
      padding-bottom: 100%;
      overflow: hidden;
      pointer-events: none;
      opacity: 0;
      transition: opacity 0.4s;
    }

    .info {
      position: absolute;
      bottom: 15px;
      left: 15px;
      opacity: 0;
      transition: all 0.4s;
      z-index: 2;

      h6 {
        margin: 0;
        line-height: 1.4;
        font-weight: 500;
        font-size: 18px;
      }

      span {
        font-size: 13px;
        color: var(--primary-color);
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 5px;
      left: 0;
      right: 0;
      bottom: 5px;
      background: linear-gradient(to right, var(--primary-color), var(--help-color), var(--danger-color));
      opacity: 0;
      z-index: -1;
      transition: all 0.7s;
    }

    &:hover {
      &:before {
        opacity: 0.5;
      }

      .circle {
        opacity: 1;
      }

      .info {
        opacity: 1;
      }

      &:after {
        opacity: 1;
        transform: rotate(4deg);
      }
    }
  }
`;
