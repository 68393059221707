import React from 'react';
import { Breadcrumbs } from 'components/breadcrumbs';
import ClaimIdForm from 'components/claim-id-form/ClaimIdForm';
import PreLoading from 'components/pre-loading';
import Benefits from 'components/benefits';
import { Section } from './index.styled';
import data from './data.json';

const Points = [
  {
    text: 'Business cards are misplaced and always hard to find when you need them.  Many times the data has changed.',
  },
  {
    text: 'Did you know that up to 88% of printed cards you hand out are thrown out in a week? That is 40 to 50 Billion cards that end up in landfills! Be green',
  },
  {
    text: 'Updating your information is easy.  No hassle to reprint!',
  },
  {
    text: 'Share your information easily via your smartphone anytime.',
  },
  {
    text: 'Digital cards cost less and they leave a great first impression and save others a lot of time by automatically uploading your contact information.',
  },
];
const PerformancePage = () => {
  return (
    <Section>
      <PreLoading label="Performance" />
      <Breadcrumbs
        className="breadcrumbs"
        label="PERFORMANCE"
        text="Claim your Online Identity and begin to reap rewards."
      />
      <div className="grid">
        <div className="col-12 md:col-10 md:col-offset-1">
          <img src="/assets/images/wall_performance.png" width="100%" alt="Performance" />
          <div className="grid">
            <div className="col-10 col-offset-1">
              <h4>How will your identity perform online?</h4>
              <h6 className="my-4">
                There are several reasons why an online crwdid is the best choice. It’s convenient, eco-friendly,
                affordable, contactless, and leads to better follow-ups.
              </h6>
              <p className="my-5">
                Our crwdid is pretty much the same as paper business cards - except it is created and exchanged via the
                Internet instead of the usual paper printed versions.
              </p>
              <h6>ENJOY FINANCIAL PERFORMANCE ACROSS THE BLOCKCHAIN ECOSYSTEM! (COMING SOON)</h6>
              <p className="my-5">
                Enjoy rebates in crwdmarket every time you make a purchase. Enjoy access to pre-IPO companies as you
                explore your wallet on crwdcapital. Enjoy the opportunity to help your company increase your earnings
                and raise capital on crwdfinance
              </p>

              <ul>
                {Points.map((point, i) => (
                  <li key={i}>
                    <span>0{i + 1}</span> {point.text}.
                  </li>
                ))}
              </ul>

              <div className="quotes text-center">
                <p>CLAIM YOUR ONLINE CONTACT CARD TODAY!</p>
              </div>

              <Benefits data={data} />
              <ClaimIdForm />
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};
export default PerformancePage;
