
import styled from 'styled-components';

export const Section = styled.section`
  background: var(--surface-section);
  padding-bottom: 20px;

  img {
    width: 100%;
    height: 200px;
  }

  .p-button-link {
    color: var(--text-color);
    font-weight: 400;
    font-size: 16px;
    text-decoration: underline;

    &:hover {
      color: var(--text-color) !important;
      opacity: 0.9;
    }
  }

  @media (max-width: 576px) {
  }
`;
