
import styled from 'styled-components';

interface Props {
  value: number;
}
export const Progress = styled.div<Props>`
  margin-bottom: 50px;

  h6 {
    font-size: 13px;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 10px !important;
    line-height: 1.4;
  }

  .p-progressbar {
    height: 7px;
    overflow: visible;

    &:before {
      content: '';
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 8px solid #151424;
      position: absolute;
      left: ${({ value }) => `${value === 100 ? value - 2 : value}%`};
      bottom: 12px;
      transition: left 0.2s ease-in-out;
    }

    &:after {
      content: ${({ value }) => `'${value}%'`};
      position: absolute;
      left: ${({ value }) => `${value === 100 ? value - 16 : value}%`};
      top: -45px;
      padding: 7px 15px;
      border-radius: 1px;
      background: #151424;
      font-size: 12px;
      font-weight: 600;
      transition: left 0.2s ease-in-out;
    }

    .p-progressbar-value-animate {
      transition: left 0.2s ease-in-out;
    }

    .p-progressbar-value {
      background: linear-gradient(to right, #00cfe5, #c200db, #e80074);
    }
  }
`;

export const Section = styled.section`
  padding: 120px 0 90px;
  overflow: hidden;

  .sec-head {
    position: relative;

    h6 {
      margin-bottom: 20px;
    }

    h3 {
      margin: 0;
      line-height: 1.4;
      font-weight: 500;
      font-size: 35px;
      margin-bottom: 40px;
    }
  }

  @media (max-width: 576px) {
    .sec-head {
      h3 {
        font-size: 28px !important;
        line-height: 35px !important;
      }
    }
  }
`;

export const Item = styled.div`
  padding: 0;

  .img {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0px;
      left: 0;
      right: 0;
      bottom: 0px;
      background: #000;
      opacity: 0;
      transition: all 0.4s;
    }

    img {
      width: 100%;
      height: auto;
    }

    .circle {
      position: absolute;
      top: -30%;
      right: -50%;
      width: 100%;
      padding-bottom: 100%;
      overflow: hidden;
      pointer-events: none;
      opacity: 0;
      transition: opacity 0.4s;
    }

    .info {
      position: absolute;
      bottom: 15px;
      left: 15px;
      opacity: 0;
      transition: all 0.4s;
      z-index: 2;

      h6 {
        margin: 0;
        line-height: 1.4;
        font-weight: 500;
        font-size: 18px;
      }

      span {
        font-size: 13px;
        color: var(--primary-color);
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 5px;
      left: 0;
      right: 0;
      bottom: 5px;
      background: linear-gradient(to right, var(--primary-color), var(--help-color), var(--danger-color));
      opacity: 0;
      z-index: -1;
      transition: all 0.7s;
    }
  }
`;
