
import styled from 'styled-components';

export const Section = styled.section`
  padding-bottom: 120px;

  .breadcrumbs {
    background: transparent;
    padding-top: 230px;

    h3 {
      font-size: 80px;
      font-weight: 600;
    }
  }

  h4 {
    margin-top: 60px;
    font-weight: 700;
    font-size: 30px;
  }

  h6 {
    font-weight: 500;
    font-size: 18px;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin: 10px 0;

      span {
        font-size: 12px;
        margin-right: 10px;
      }
    }
  }

  .quotes {
    background: rgba(255, 255, 255, 0.02);
    padding: 30px;
    margin: 30px 0;

    p {
      font-size: 18px;
      font-style: italic;
    }
  }

  img {
    width: 100%;
  }

  @media (max-width: 576px) {
    .breadcrumbs {
      padding-top: 200px;

      h3 {
        font-size: 30px;
      }
    }
  }
`;
